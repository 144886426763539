import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import * as CryptoJS from 'crypto-js';

export const BlobService = {
    downloadBlob,
    deleteBlob,
    uploadBlob,
    uploadKeyHandOuts
}

async function downloadBlob(containerName, blobName) {
    try {
        /* SAS Key Gen BEGIN */     
        const end = new Date(new Date().getTime() + (30 * 60 * 1000));
        const signedPermissions = 'rwdlac';
        const signedService = 'b';
        const signedResourceType = 'sco';
        const signedExpiry = end.toISOString().substring(0, end.toISOString().lastIndexOf('.')) + 'Z';
        const signedProtocol = 'https';
        const signedVersion = '2020-02-10';
      
        const StringToSign =
            process.env.REACT_APP_ACCOUNT_NAME + '\n' +
            signedPermissions + '\n' +
            signedService + '\n' +
            signedResourceType + '\n\n'  +
            signedExpiry + '\n\n'+
            signedProtocol + '\n' +
            signedVersion + '\n';

       var str =CryptoJS.HmacSHA256(StringToSign,CryptoJS.enc.Base64.parse(process.env.REACT_APP_ACCOUNT_KEY));
       var sig = CryptoJS.enc.Base64.stringify(str);     
       
       const sasToken =`sv=${(signedVersion)}&ss=${(signedService)}&srt=${(signedResourceType)}&sp=${(signedPermissions)}&se=${encodeURIComponent(signedExpiry)}&spr=${(signedProtocol)}&sig=${encodeURIComponent(sig)}`;
      
        /* SAS Key Gen END */

        const DLblobServiceClient = new BlobServiceClient(`https://midwestblob.blob.core.windows.net/?${sasToken}`);
        const DLcontainerClient = DLblobServiceClient.getContainerClient(containerName);
        const DLblobClient = DLcontainerClient.getBlobClient(blobName);
        const downloadBlockBlobResponse = await DLblobClient.download();
        const downloaded = await URL.createObjectURL((await downloadBlockBlobResponse.blobBody));
        return downloaded;
    } catch (e) {
        // console.log(e)
        return false;
    }
}

async function deleteBlob(containerName, blobName) {
    try {                        
            /* SAS Key Gen BEGIN */     
            const end = new Date(new Date().getTime() + (30 * 60 * 1000));
            const signedPermissions = 'rwdlac';
            const signedService = 'b';
            const signedResourceType = 'sco';
            const signedExpiry = end.toISOString().substring(0, end.toISOString().lastIndexOf('.')) + 'Z';
            const signedProtocol = 'https';
            const signedVersion = '2020-02-10';

            const StringToSign =
                process.env.REACT_APP_ACCOUNT_NAME + '\n' +
                signedPermissions + '\n' +
                signedService + '\n' +
                signedResourceType + '\n\n'  +
                signedExpiry + '\n\n'+
                signedProtocol + '\n' +
                signedVersion + '\n';

            var str =CryptoJS.HmacSHA256(StringToSign,CryptoJS.enc.Base64.parse(process.env.REACT_APP_ACCOUNT_KEY));
            var sig = CryptoJS.enc.Base64.stringify(str);     

            const sasToken =`sv=${(signedVersion)}&ss=${(signedService)}&srt=${(signedResourceType)}&sp=${(signedPermissions)}&se=${encodeURIComponent(signedExpiry)}&spr=${(signedProtocol)}&sig=${encodeURIComponent(sig)}`;            
            /* SAS Key Gen END */
            const containerClient = new ContainerClient(`https://midwestblob.blob.core.windows.net/${containerName}/?${sasToken}`);           
            await containerClient.deleteBlob(blobName);
            return true;
    } catch (e) {
        // console.log(e)
        return false;
    }
}

async function uploadBlob(containerName, blobName, imageFile) {
    try {
            /* SAS Key Gen BEGIN */     
            const end = new Date(new Date().getTime() + (30 * 60 * 1000));
            const signedPermissions = 'rwdlac';
            const signedService = 'b';
            const signedResourceType = 'sco';
            const signedExpiry = end.toISOString().substring(0, end.toISOString().lastIndexOf('.')) + 'Z';
            const signedProtocol = 'https';
            const signedVersion = '2020-02-10';

            const StringToSign =
                process.env.REACT_APP_ACCOUNT_NAME + '\n' +
                signedPermissions + '\n' +
                signedService + '\n' +
                signedResourceType + '\n\n'  +
                signedExpiry + '\n\n'+
                signedProtocol + '\n' +
                signedVersion + '\n';

            var str =CryptoJS.HmacSHA256(StringToSign,CryptoJS.enc.Base64.parse(process.env.REACT_APP_ACCOUNT_KEY));
            var sig = CryptoJS.enc.Base64.stringify(str);     

            const sasToken =`sv=${(signedVersion)}&ss=${(signedService)}&srt=${(signedResourceType)}&sp=${(signedPermissions)}&se=${encodeURIComponent(signedExpiry)}&spr=${(signedProtocol)}&sig=${encodeURIComponent(sig)}`;

            /* SAS Key Gen END */
            const blobServiceClient = new BlobServiceClient(`https://midwestblob.blob.core.windows.net/?${sasToken}`);
            let content = await (await fetch(imageFile)).blob() ;
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blobOptions = { blobHTTPHeaders: { blobContentType: 'image/jpeg' } };
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
            await blockBlobClient.upload(content, content.size, blobOptions);
            return true;
    } catch (e) {
        // console.log(e)
        return false;
    }
}

async function uploadKeyHandOuts(containerName, blobName, imageFile) {
    try {
            /* SAS Key Gen BEGIN */     
            const end = new Date(new Date().getTime() + (30 * 60 * 1000));
            const signedPermissions = 'rwdlac';
            const signedService = 'b';
            const signedResourceType = 'sco';
            const signedExpiry = end.toISOString().substring(0, end.toISOString().lastIndexOf('.')) + 'Z';
            const signedProtocol = 'https';
            const signedVersion = '2020-02-10';

            const StringToSign =
                process.env.REACT_APP_ACCOUNT_NAME + '\n' +
                signedPermissions + '\n' +
                signedService + '\n' +
                signedResourceType + '\n\n'  +
                signedExpiry + '\n\n'+
                signedProtocol + '\n' +
                signedVersion + '\n';

            var str =CryptoJS.HmacSHA256(StringToSign,CryptoJS.enc.Base64.parse(process.env.REACT_APP_ACCOUNT_KEY));
            var sig = CryptoJS.enc.Base64.stringify(str);     

            const sasToken =`sv=${(signedVersion)}&ss=${(signedService)}&srt=${(signedResourceType)}&sp=${(signedPermissions)}&se=${encodeURIComponent(signedExpiry)}&spr=${(signedProtocol)}&sig=${encodeURIComponent(sig)}`;

            /* SAS Key Gen END */
            const blobServiceClient = new BlobServiceClient(`https://midwestblob.blob.core.windows.net/?${sasToken}`);
            let content = await (await fetch(imageFile)).blob() ;
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blobOptions = { blobHTTPHeaders: { blobContentType: 'image/jpeg' } };
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
            await blockBlobClient.upload(content, content.size, blobOptions);
            return true;
    } catch (e) {
        // console.log(e)
        return false;
    }
}