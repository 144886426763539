import React from 'react';

import LazyKeysComponent from '../components/LazyKeysComponent';

//Page 3 for API, page 2 for internal routing
class LazyKeys extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            
        }

    }

    render() {
        return(
            <div>
                <LazyKeysComponent />
            </div>
        );
    }
}

export default LazyKeys;