import React from 'react';

import Layout from '../components/Layout';
import { KeyService, RecordsService } from '../services/FetchServices';
import LazyDatatable from '../components/LazyDatatable';
import Label from '../components/inputComponents/Label';
import TextBox from '../components/inputComponents/textbox';
import InputSelect from '../components/inputComponents/InputSelect';
import DoorsComponent from '../components/DoorsComponent';
import KeyHistoryComponent from '../components/KeyHistoryComponent';

import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import {Dialog} from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { BrowserView, MobileView } from 'react-device-detect';
import { TabView,TabPanel } from 'primereact/tabview';

//Page 3 for API, page 2 for internal routing
class LazyKeysComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            keys: [],
            isLoading: true,
            selectedColumns: [
                {field:'uiY_SYSTEM', header:'System'},
                {field:'uiY_LEVEL', header:'Level'},
                {field:'fullname', header:'Associate'},
                {field:'uiY_FULLCODE', header:'Key Symbol'},
                {field:'uiY_DESC', header:'Description'},
                {field:'cabname', header:'Key Storage Name'},
                {field:'uiY_ISSUEDATE', header:'Issue Date'},
                {field:'uiY_DUEDATE', header:'Return Date'},
                {field:'uiY_HOOKNUMBER', header:'Hook #'},
                {field:'uiY_NOTES', header:'Notes'},
                {field:'hookdesc', header:'Hook Description'}
            ],
            columnWidthTracker: "133,133,133,133,133,133,133,133,133,133,133",
            defaultOrder:["uiY_SYSTEM", "uiY_LEVEL", "fullname", "uiY_FULLCODE", "uiY_DESC", "cabname", "uiY_ISSUEDATE", "uiY_DUEDATE", "uiY_HOOKNUMBER", "uiY_NOTES", "hookdesc"],
            secRole: '3',
            disabled: false,
            reason:'Select Reason',
            activeIndex: 0,
            dataLoading: true,
            csvRecords: []
        }
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.renderEditFooter  = this.renderEditFooter.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleFieldSave = this.handleFieldSave.bind(this);
        this.showEditResults = this.showEditResults.bind(this);
        this.fetchKeysMethod = this.fetchKeysMethod.bind(this);
        this.loadLazyData = this.loadLazyData.bind(this);
        this.loadLazyCsvData = this.loadLazyCsvData.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        // await this.fetchKeysMethod();

        if(parsedUserData.secString[9]==='2') {
            this.setState({ disabled: true })
        }
        
        let parsedTableData = JSON.parse(sessionStorage.getItem("keysTableData"));
        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        this.setState({isLoading: false, secRole: parsedUserData.secString[9], orgID: parsedUserData.uiU_M1ORGID})
    }

    async loadLazyData(lazyParams) {
        // console.log(lazyParams.orgID)
        if(lazyParams.orgID!==undefined) {
            this.setState({dataLoading: true})
            await KeyService.FetchLazyKeys(lazyParams).then(data => this.setState({keys: data.recordlist, loading: false, totalRecords: data.count, dataLoading:false}));
        }
    }

    async loadLazyCsvData(lazyParams) {
        // this.setState({dataLoading: true})
        console.log("api call handler")
        const csvRecords = await KeyService.FetchLazyKeys(lazyParams)
        // .then(data => this.setState({csvRecords: data.recordlist, dataLoading: false}))
        // this.setState({dataLoading: false})
        // return csvRecords.recordlist;
        this.setState({csvRecords: csvRecords.recordlist})
    }

    async fetchKeysMethod() {
        // let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        // await KeyService.FetchLazyKeys( parsedUserData.uiU_M1ORGID, this.props.employeeID, this.props.recordID, 0 )
        // .then(data => tempArray=data.keylist)

        // let tempCabArray=[{value:0, label:'Select Key Storage'}];
        // let tempCabVar = (await RecordsService.fetchCabinets( parsedUserData.uiU_M1ORGID, 0 )).cabinetlist;
        
        // for(let i=0; i<tempCabVar.length; i++) {
        //     tempCabArray.push({
        //         "value": tempCabVar[i].uiB_ID,
        //         "label": tempCabVar[i].uiB_NAME
        //     })
        // }
        // this.setState({cabinetOptions: tempCabArray, orgID: parsedUserData.uiU_M1ORGID})

        // this.setState({ keys: tempArray. })
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    renderEditFooter() {
        if(this.state.disabled) {
            return(
                <div>
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} />
                </div>
            );
        } else {
            return (
                <div>
                    <Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleFieldSave} />
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} />
                </div>
            );
        }
    }

    async editRecord(e) {
        if(this.state.secRole==='1') {
            return;
        } else {
            if(e.data.uiY_CABINETID===0) {
                let hookOptions = [{value:0, label:'Select Hook'}]
                this.setState({hookNumOptions: hookOptions})
            } else {
                let tempArray=[];
                let tempHookArray = (await RecordsService.fetchHooks(this.state.orgID, e.data.uiY_CABINETID)).hooklist;
                for(let i=0; i<tempHookArray.length; i++) {
                    tempArray.push({
                        "value": tempHookArray[i].uiT_HOOKNUMBER,
                        "label": tempHookArray[i].uiT_HOOKNUMBER 
                    })
                }
                this.setState({hookNumOptions: tempArray})
            }
            this.setState({
                editRowD: true,
                editSystem: e.data.uiY_SYSTEM,
                editLevel: e.data.uiY_LEVEL,
                editStatus: e.data.fullname,
                editFullCode: e.data.uiY_FULLCODE,
                editBittings: e.data.bittings,
                editDesc: e.data.uiY_DESC,
                editNotes: e.data.uiY_NOTES,
                hookNum: e.data.uiY_HOOKNUMBER,
                cabinet: e.data.uiY_CABINETID,
                serialNum : e.data.uiY_SN,
                keyway: e.data.uiY_KEYWAY,
                keyRecordID: e.data.uiY_KEYRECORDID,
                keySymbol: e.data.uiY_FULLCODE,
                pins: e.data.pins,
                cabname: e.data.cabname,
                wo: e.data.wo,
                product: e.data.uiY_PRODUCT,
                keyID: e.data.uiY_ID,
                sigfile: e.data.sigfile,
                retsigfile: e.data.retsigfile,
                keymark: e.data.uiY_KEYMARK
            })
        }
    }

    async handleFieldChange(e) {
        if(e.target.id==='notes') {
            this.setState({ editNotes: e.target.value })
        } else if(e.target.id==='desc') {
            this.setState({ editDesc: e.target.value })
        } else if(e.target.id==='reason') {
            this.setState({ reason: e.target.value })
        } else if(e.target.id==='cabinet') {
            if(e.target.value===0) {
                let hookOptions = [{value:0, label:'Select Hook'}]
                this.setState({ 
                    cabinet : 0,
                    hookNum: 0,
                    hookNumOptions: hookOptions
                })
            } else {
                let tempArray=[];
                let tempHookArray = (await RecordsService.fetchHooks(this.state.orgID, e.target.value)).hooklist;
                for(let i=0; i<tempHookArray.length; i++) {
                    tempArray.push({
                        "value": tempHookArray[i].uiT_HOOKNUMBER,
                        "label": tempHookArray[i].uiT_HOOKNUMBER 
                    })
                }
                this.setState({hookNumOptions: tempArray, cabinet: e.target.value})
            }
        } else if(e.target.id==='hookNum') {
            this.setState({ hookNum: e.target.value })
        } else if(e.target.id==='product') {
            this.setState({ product: e.target.value })
        }
    }

    handleClose() {
        this.setState({ 
            editRowD: false,
            editSystem: '',
            editLevel: '',
            editStatus: '',
            editFullCode: '',
            editBittings: '',
            editDesc: '',
            editNotes: '',
            hookNum: '',
            cabinet: '',
            serialNum : '',
            keyway: '',
            keyRecordID: '',
            keySymbol: '',
            pins: '',
            cabname: '',
            wo: '',
            product: '',
            keyID: '',
            sigfile: '',
            retsigfile: '',
            keymark: '',
            activeIndex: 0
        })
    }

    handleFieldSave = async e => {
        let userData = JSON.parse(sessionStorage.getItem('userData'));
        if(this.state.hookNum==="") {
            await KeyService.saveKey(userData.uiU_M1ORGID, this.state.keyID, this.state.product, this.state.editNotes, this.state.editDesc, this.state.cabinet, 0)
            .then(data => this.showEditResults(data));
        } else {
            await KeyService.saveKey(userData.uiU_M1ORGID, this.state.keyID, this.state.product, this.state.editNotes, this.state.editDesc, this.state.cabinet, this.state.hookNum)
            .then(data => this.showEditResults(data));
        }
        await this.fetchKeysMethod();
        this.handleClose();
    }

    showEditResults(data) {
        if(data.success===false) {
            this.messages.show({severity: 'error', summary: data.message, life:3000});
        } else {
            this.messages.show({ severity:'success', summary: data.message, life:2000 })
        }
    }

    render() {
        const { editSystem, editNotes, editStatus, editBittings } = this.state;
        const editFooter=this.renderEditFooter();
        if((this.state.secRole==='' && !this.state.isLoading) || (this.state.secRole==='0' && !this.state.isLoading)) {
            return(<Layout><div>Security Role prevents you from seeing data</div></Layout>)
        } else {
            return (
                <div>
                    <Layout>
                        {(this.state.keys.length===0 && !this.state.isLoading) ? (<div>Contact Midwest Security Products to add keys</div>) : (
                        <div className='container-div'>
                            <Messages ref={(el) => this.messages = el} />
                            <LazyDatatable 
                                columnArray = {[
                                    {field:'uiY_SYSTEM', header:'System'},
                                    {field:'uiY_LEVEL', header:'Level'},
                                    {field:'fullname', header:'Associate'},
                                    {field:'uiY_FULLCODE', header:'Key Symbol'},
                                    {field:'uiY_DESC', header:'Description'},
                                    {field:'cabname', header:'Key Storage Name'},
                                    {field:'uiY_ISSUEDATE', header:'Issue Date'},
                                    {field:'uiY_DUEDATE', header:'Return Date'},
                                    {field:'uiY_HOOKNUMBER', header:'Hook #'},
                                    {field:'uiY_NOTES', header:'Notes'},
                                    {field:'hookdesc', header:'Hook Description'}
                                ]}

                                csvHeader = {[
                                    {key:'uiY_SYSTEM', label:'System'},
                                    {key:'uiY_LEVEL', label:'Level'},
                                    {key:'fullname', label:'Associate'},
                                    {key:'uiY_FULLCODE', label:'Key Symbol'},
                                    {key:'uiY_DESC', label:'Description'},
                                    {key:'cabname', label:'Key Storage Name'},
                                    {key:'uiY_ISSUEDATE', label:'Issue Date'},
                                    {key:'uiY_DUEDATE', label:'Return Date'},
                                    {key:'uiY_HOOKNUMBER', label:'Hook #'},
                                    {key:'uiY_NOTES', label:'Notes'},
                                    {key:'hookdesc', label:'Hook Description'}
                                ]}
                                
                                defaultOrder={["uiY_SYSTEM", "uiY_LEVEL", "fullname", "uiY_FULLCODE", "uiY_DESC", "cabname", "uiY_ISSUEDATE", "uiY_DUEDATE", "uiY_HOOKNUMBER", "uiY_NOTES", "hookdesc"]}

                                records = {this.state.keys}
                                pageNumber={3}
                                tableName={'Keys'}
                                exportFileName={'keys.csv'}
                                tableData={'keysTableData'}
                                filterData={'keysFilterData'}
                                columnWidthTracker={this.state.columnWidthTracker}
                                isLoading={this.state.isLoading}
                                selectedColumns={this.state.selectedColumns}
                                handleRowClick={this.editRecord}
                                handleWidth={this.handleWidth}
                                handleSelectedColumns={this.handleSelectedColumns}
                                loadLazyData={this.loadLazyData}
                                loadLazyCsvData={this.loadLazyCsvData}
                                csvRecords={this.state.csvRecords}
                                totalRecords={this.state.totalRecords}
                                dataLoading={this.state.dataLoading}
                            />
                        </div>
                        )}
                    </Layout>
                    <Dialog header='Edit Key' visible={this.state.editRowD} onHide={this.handleClose} baseZIndex={1300} maximized focusOnShow={false} footer={editFooter} closable={false}>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                            <TabPanel header='Info' style={{margin:'auto'}}>
                                <div>
                                    <BrowserView>
                                        {(window.screen.width>1150&&window.screen.width<1300) ? (
                                            <div className='p-grid p-justify-start'>
                                                <div className='p-col-4 p-grid'>
                                                    <Label label={'Key Record: '} value={this.state.keyRecordID} />
                                                    <Label label={'System Type: '} value={editSystem} />
                                                    <Label label={'Pins: '} value={this.state.pins} />
                                                    <Label label={'Key Symbol: '} value={this.state.keySymbol} />
                                                    <Label label={'Bittings: '} value={editBittings} />
                                                </div>
                                                <div className='p-col-4 p-grid'>
                                                    <Label label={'Serial Number: '} value={this.state.serialNum} />
                                                    <Label label={'Created On Job: '} value={this.state.wo} />
                                                    <Label label={'Associate: '} value={editStatus} />
                                                    <Label label={'Keyway: '} value={this.state.keyway} />
                                                </div>
                                                <div className='p-col-4 p-grid p-justify-end'>
                                                    <InputSelect value={this.state.cabinet} id={'cabinet'} handleFieldChange={this.handleFieldChange} label={'Key Storage: '} options={this.state.cabinetOptions} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.hookNum} id={'hookNum'} handleFieldChange={this.handleFieldChange} label={'Hook/Peg #: '} options={this.state.hookNumOptions} disabled={this.state.disabled} />
                                                    <TextBox id={'product'} value={this.state.product} handleFieldChange={this.handleFieldChange} label={"Product: "} disabled={this.state.disabled} />
                                                    <TextBox id={'desc'} value={this.state.editDesc} handleFieldChange={this.handleFieldChange} label={"Description: "} disabled={this.state.disabled} area />
                                                </div>
                                                <TextBox id={'notes'} value={this.state.editNotes} handleFieldChange={this.handleFieldChange} label={"Notes: "} disabled={this.state.disabled} area rows={5} />
                                            </div>
                                        ) : (
                                            <div className='p-grid p-justify-even'>
                                                <div className='p-col-3 p-grid'>
                                                    <Label label={'Key Record: '} value={this.state.keyRecordID} />
                                                    <Label label={'System Type: '} value={editSystem} />
                                                    <Label label={'Pins: '} value={this.state.pins} />
                                                    <Label label={'Key Symbol: '} value={this.state.keySymbol} />
                                                    <Label label={'Bittings: '} value={editBittings} />
                                                </div>
                                                <div className='p-col-3 p-grid'>
                                                    <Label label={'Serial Number: '} value={this.state.serialNum} />
                                                    <Label label={'Created On Job: '} value={this.state.wo} />
                                                    <Label label={'Associate: '} value={editStatus} />
                                                    <Label label={'Keyway: '} value={this.state.keyway} />
                                                </div>
                                                <div className='p-col-3 p-grid p-justify-end'>
                                                    <InputSelect value={this.state.cabinet} id={'cabinet'} handleFieldChange={this.handleFieldChange} label={'Key Storage: '} options={this.state.cabinetOptions} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.hookNum} id={'hookNum'} handleFieldChange={this.handleFieldChange} label={'Hook/Peg #: '} options={this.state.hookNumOptions} disabled={this.state.disabled} />
                                                    <TextBox id={'product'} value={this.state.product} handleFieldChange={this.handleFieldChange} label={"Product: "} disabled={this.state.disabled} />
                                                    <TextBox id={'desc'} value={this.state.editDesc} handleFieldChange={this.handleFieldChange} label={"Desc: "} disabled={this.state.disabled} area/>
                                                </div>
                                                <div className='p-col-3 p-grid'>
                                                    <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Notes</strong></label><InputTextarea rows={10} className='p-col-8' disabled={this.state.disabled} value={editNotes} type="text" id='notes' onChange={(e) => this.handleFieldChange(e)}/></div>
                                                </div>
                                            </div>
                                        )}
                                    </BrowserView>
                                    <MobileView>
                                        <div className='p-grid p-justify-even'>
                                            <Label mobile label={'Key Record: '} value={this.state.keyRecordID} />
                                            <Label mobile label={'System Type: '} value={editSystem} />
                                            <Label mobile label={'Pins: '} value={this.state.pins} />
                                            <Label mobile label={'Key Symbol: '} value={this.state.keySymbol} />
                                            <Label mobile label={'Bittings: '} value={editBittings} />
                                            <Label mobile label={'Serial Number: '} value={this.state.serialNum} />
                                            <Label mobile label={'Created On Job: '} value={this.state.wo} />
                                            <Label mobile label={'Associate: '} value={editStatus} />
                                            <Label mobile label={'Keyway: '} value={this.state.keyway} />
                                            <InputSelect mobile value={this.state.cabinet} id={'cabinet'} handleFieldChange={this.handleFieldChange} label={'Key Storage: '} options={this.state.cabinetOptions} disabled={this.state.disabled} />
                                            <InputSelect mobile value={this.state.hookNum} id={'hookNum'} handleFieldChange={this.handleFieldChange} label={'Hook/Peg #: '} options={this.state.hookNumOptions} disabled={this.state.disabled} />
                                            <TextBox mobile id={'product'} value={this.state.product} handleFieldChange={this.handleFieldChange} label={"Product: "} disabled={this.state.disabled} />
                                            <TextBox mobile id={'desc'} value={this.state.editDesc} handleFieldChange={this.handleFieldChange} label={"Description: "} disabled={this.state.disabled} />
                                            <TextBox mobile area rows={5} id={'notes'} value={this.state.editDesc} handleFieldChange={this.handleFieldChange} label={"Notes: "} disabled={this.state.disabled} />
                                        </div>
                                    </MobileView>
                                </div>
                            </TabPanel>
                            <TabPanel header='Doors Operated By This Key'>
                                <DoorsComponent
                                    isComponent
                                    hasButton={false}
                                    header={'Doors Operated By This Key'}
                                    coreMarkID={0}
                                    keymark={this.state.keymark}
                                    keyway={this.state.keyway}
                                    system={this.state.editSystem}
                                    doorID={0}
                                    buildingID={0}
                                />
                            </TabPanel>
                            <TabPanel header='Key History'>
                                <KeyHistoryComponent userID={0} keyID={this.state.keyID} />
                            </TabPanel>
                        </TabView>
                    </Dialog>
                </div>
            );
        }
    }
}

export default LazyKeysComponent;