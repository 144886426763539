import React from 'react';

import GeneralDatatable from '../components/GeneralDatatable';
import { SupportService } from '../services/FetchServices';
import TicketFilesComponent from '../components/TicketFilesComponent';

import {Dialog} from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';

class CaseDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            selectedColumns:[
                {field:'tickeT_NO', header:'Case Number'},
                {field:'entrY_DATE', header:'Entry Date'},
                {field:'updateD_BY', header:'Updated By'},
                {field:'comment', header:'Comment'}
            ],
            columnWidthTracker:"365.75,365.75,365.75,365.75",
            isLoading: true,
            comment: "",
            disabled: false,
            detailDialog: false,
            filE1_PATH: "",
            filE2_PATH: "",
            filE3_PATH: "",
            filE4_PATH: "",
            filE5_PATH: "",
            editRow: false
        }

        this.editRecord = this.editRecord.bind(this);
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleNewCaseDetail = this.handleNewCaseDetail.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount(props) {
        let parsedTableData = JSON.parse(sessionStorage.getItem('supportCaseDetailsTableData'));

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        this.setState({ 
            isLoading: false
        })
    }

    handleNewCaseDetail() {
        this.setState({detailDialog: true})
    }

    editRecord(e) {
        // console.log(e.data)
        this.setState({
            comment: e.data.comment,
            entrY_DATE: e.data.entrY_DATE,
            updateD_BY: e.data.updateD_BY,
            editRow: true,
            detailDialog: true,
            disabled: true
        })
    }

    handleCancel() {
        this.setState({
            comment: "",
            entrY_DATE: "",
            updateD_BY: "",
            editRow: false,
            detailDialog: false,
            disabled: false
        })
    }

    async handleSave() {
        await SupportService.createTicketDetail( this.props.orgID, this.props.ticketNum, this.props.updatedBy, this.state.comment, this.state.filE1_PATH, this.state.filE2_PATH, this.state.filE3_PATH, this.state.filE4_PATH, this.state.filE5_PATH)
        .then(data => this.showResults(data));
        this.handleCancel();
        this.props.updateCaseDetailData(this.props.ticketNum);
    }

    showResults(data) {
        this.messages.clear();
        if(data.res) {
            this.messages.show({severity: 'success', summary: "Case Details Updated", life:1000});
        } else {
            this.messages.show({severity: 'error', summary: "Failed to save case details", sticky: true});
        }
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }


    handleFieldChange(e) {
        if(e.target.id==='comment') {
            this.setState({ comment: e.target.value })
        }
    }

    renderButtons() {
        if(this.state.editRow===true) {
            return(
                <div>
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCancel} />
                </div>
            )
        } else {
            return(
                <div>
                    <Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleSave} />
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCancel} />
                </div>
            )
        }
    }

    render(){    
        // console.log(this.state.detailDialog)
        const buttonFooter=this.renderButtons();
        if(this.props.detailsData.length===0 && !this.state.isLoading && this.state.detailDialog===false) {
            return(
                <div>
                    <div style={{marginBottom:'40px'}}><Button label='Update Ticket' icon='pi pi-plus' onClick={this.handleNewCaseDetail}/></div>
                </div>
            );  
        } else {
            return(
                <div>
                    <Messages ref={(el) => this.messages = el} />
                    <GeneralDatatable
                        columnArray = {[
                            {field:'tickeT_NO', header:'Case Number'},
                            {field:'entrY_DATE', header:'Entry Date'},
                            {field:'updateD_BY', header:'Updated By'},
                            {field:'comment', header:'Comment'}
                        ]}

                        csvHeader = {[
                            {key:'tickeT_NO', label:'Case Number'},
                            {key:'entrY_DATE', label:'Entry Date'},
                            {key:'updateD_BY', label:'Updated By'},
                            {key:'comment', label:'Comment'}
                        ]}
                        
                        defaultOrder={["tickeT_NO", "entrY_DATE", "updateD_BY", "comment"]}
                        tableRecords = {this.props.detailsData}
                        pageNumber={38}
                        tableName={'Case Details'}
                        exportFileName={'SupportCaseDetails.csv'}
                        tableData={'supportCaseDetailsTableData'}
                        filterData={'supportCaseDetailsFilterData'}
                        columnWidthTracker={this.state.columnWidthTracker}
                        isLoading={this.state.isLoading}
                        selectedColumns={this.state.selectedColumns}
                        handleRowClick={this.editRecord}
                        handleWidth={this.handleWidth}
                        handleSelectedColumns={this.handleSelectedColumns}
                        hasButton={this.props.hasButton}
                        buttonLabel={'New Case Detail'}
                        onButtonClick={this.handleNewCaseDetail}
                    />
                    <Dialog header={"Case: " + this.props.ticketNum + ": Case Detail"}  visible={this.state.detailDialog} footer={buttonFooter} onHide={this.handleCancel} baseZIndex={1300} maximized blockScroll focusOnShow={false} closable={false}>
                        <div style={{width:'99vw', maxWidth:'100%'}}>
                            <div className="p-grid p-justify-center">
                                {this.state.editRow===true ? (
                                    <React.Fragment>
                                        <div className="p-col-12 p-lg-8">
                                            <div className='p-grid p-justify-start'>
                                                <div className="p-col-12 p-lg-2" style={{textAlign:'left'}}>
                                                        <strong>{"Date Added: "}</strong>
                                                </div>
                                                <div className='p-col-12 p-lg-12' >
                                                    <InputText placeholder={""} disabled={this.state.disabled} style={{width:'100%'}} 
                                                    value={this.state.entrY_DATE} onChange={(e) => this.handleFieldChange(e)} type='text' id={"entrY_DATE"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-lg-8">
                                            <div className='p-grid p-justify-start'>
                                                <div className="p-col-12 p-lg-2" style={{textAlign:'left'}}>
                                                        <strong>{"Added By: "}</strong>
                                                </div>
                                                <div className='p-col-12 p-lg-12' >
                                                    <InputText placeholder={""} disabled={this.state.disabled} style={{width:'100%'}} 
                                                    value={this.state.updateD_BY} onChange={(e) => this.handleFieldChange(e)} type='text' id={"updateD_BY"} />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : (null)}
                                
                                <div className="p-col-12 p-lg-8">
                                    <div className='p-grid p-justify-start'>
                                        <div className="p-col-12 p-lg-2" style={{textAlign:'left'}}>
                                                <strong>{"Comment: "}</strong>
                                        </div>
                                        <div className='p-col-12 p-lg-12' >
                                            <InputTextarea autoResize rows={5} placeholder={"[Enter Comment Here]"} disabled={this.state.disabled} style={{width:'100%'}} 
                                            value={this.state.comment} onChange={(e) => this.handleFieldChange(e)} type='text' id={"comment"} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                                {/* commented out until we are ready to do images
                                <TicketFilesComponent 
                                    blobFilePath1={`${this.state.tickeT_NO}-file1`}
                                    blobFilePath2={`${this.state.tickeT_NO}-file2`}
                                    blobFilePath3={`${this.state.tickeT_NO}-file3`}
                                    blobFilePath4={`${this.state.tickeT_NO}-file4`}
                                    blobFilePath5={`${this.state.tickeT_NO}-file5`}
                                    containerName={"-case-details"}
                                    newCase={false}
                                    newCaseDetail={true}
                                /> */}
                        </div>
                    </Dialog>
                </div>
            );
        }
    }
}

export default CaseDetails;