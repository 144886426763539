import React from 'react';

import { serverAddr } from '../services/serverAddr';
import { BlobService } from '../services/BlobServices';

import { FileUpload } from 'primereact/fileupload';
import { Card } from 'primereact/card';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import { Redirect } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import Webcam from "react-webcam";

const containerToUse = serverAddr.blobContainer;
const videoConstraints = {
    facingMode: { exact: "environment" }
}

class TicketFilesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            imgSrc: [],
            redirectSupportCases: false,
            openChooseDialogState: false,
            uploadPath: "",
            cameraDialog: false
        }
        this.handleBlobDownload = this.handleBlobDownload.bind(this);
        this.uploadBlob = this.uploadBlob.bind(this);
        this.saveBeforeUpload = this.saveBeforeUpload.bind(this);
        this.openChooseDialog = this.openChooseDialog.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.openCameraDialog = this.openCameraDialog.bind(this);
        this.setWebcamRef = this.setWebcamRef.bind(this);
        this.handleCameraCancel = this.handleCameraCancel.bind(this);
        this.capture = this.capture.bind(this);
        this.saveBeforeCapture = this.saveBeforeCapture.bind(this);
    }

    async componentDidMount() {
        if(!this.props.newCase) {
            await this.handleBlobDownload(this.props.blobFilePath1);
            await this.handleBlobDownload(this.props.blobFilePath2);
            await this.handleBlobDownload(this.props.blobFilePath3);
            await this.handleBlobDownload(this.props.blobFilePath4);
            await this.handleBlobDownload(this.props.blobFilePath5);
        }
    }

    openChooseDialog(file) {
        this.setState({openChooseDialogState: true, uploadPath: file});
    }

    openCameraDialog() {
        this.setState({cameraDialog: true})
    }

    async handleBlobDownload(blobName) {
        // const blobName1 = `${this.props.blobFilePath1}`;
        const containerName = containerToUse + this.props.containerName;
        let tempArray = this.state.imgSrc;
       
        const downloadedPic = await BlobService.downloadBlob(containerName, blobName);
        if(downloadedPic!==false) {
            tempArray.push(downloadedPic);
        }
        this.setState({imgSrc: tempArray})
    }

    async saveBeforeUpload(event) {
        if(this.props.newCase) {
            await this.props.saveNewTicket();
        }
        this.uploadBlob(event);
    }

    async uploadBlob(event) {
        console.log(event.options.props.name);
        const containerName = containerToUse + this.props.containerName;
        const imgState = event.files[0].objectURL;
        let blobName;
        if(event.options.props.name==="file1") {
            blobName = this.props.blobFilePath1;
            await this.props.updateTicket(blobName, "", "", "", "");
        } else if(event.options.props.name==="file2") {
            blobName = this.props.blobFilePath2;
            await this.props.updateTicket("", blobName, "", "", "");
        } else if(event.options.props.name==="file3") {
            blobName = this.props.blobFilePath3;
            await this.props.updateTicket("", "", blobName, "", "");
        } else if(event.options.props.name==="file4") {
            blobName = this.props.blobFilePath4;
            await this.props.updateTicket("", "", "", blobName, "");
        } else if(event.options.props.name==="file5") {
            blobName = this.props.blobFilePath5;
            await this.props.updateTicket("", "", "", "", blobName);
        }
        
        await BlobService.uploadBlob(containerName, blobName, imgState);
        if(this.props.newCase) {
            this.setState({redirectSupportCases: true})
        } else {
            await this.handleBlobDownload(blobName);
        }

        this.handleCancel();
    }

    handleCancel() {
        this.setState({openChooseDialogState: false, uploadPath:""})
    }

    handleCameraCancel() {
        this.setState({cameraDialog: false})
    }

    setWebcamRef = (webcamRef) => {this.webcamRef = webcamRef};

    async saveBeforeCapture() {
        const captureImg = this.webcamRef.getScreenshot();
        if(this.props.newCase) {
            await this.props.saveNewTicket();
        }
        this.capture(captureImg);
    }

    async capture(captureImg) {
        const containerName = containerToUse + this.props.containerName;
        let blobName;
        if(this.state.uploadPath==="file1") {
            blobName = this.props.blobFilePath1;
            await this.props.updateTicket(blobName, "", "", "", "");
        } else if(this.state.uploadPath==="file2") {
            blobName = this.props.blobFilePath2;
            await this.props.updateTicket("", blobName, "", "", "");
        } else if(this.state.uploadPath==="file3") {
            blobName = this.props.blobFilePath3;
            await this.props.updateTicket("", "", blobName, "", "");
        } else if(this.state.uploadPath==="file4") {
            blobName = this.props.blobFilePath4;
            await this.props.updateTicket("", "", "", blobName, "");
        } else if(this.state.uploadPath==="file5") {
            blobName = this.props.blobFilePath5;
            await this.props.updateTicket("", "", "", "", blobName);
        }
        // await this.handlePicLoading();
        await BlobService.uploadBlob(containerName, blobName, captureImg);
        if(this.props.newCase) {
            this.setState({redirectSupportCases: true})
        } else {
            await this.handleBlobDownload(blobName);
        }
        this.handleCameraCancel();
        this.handleCancel();
    }

    renderButtons() {
        return(
            <div>
                {/* <Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleSave} /> */}
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCancel} />
            </div>
        )
    }

    renderCameraButtons() {
        return(
            <div>
                {/* <Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleSave} /> */}
                <Button label='Capture' icon='pi pi-camera' onClick={this.saveBeforeCapture} style={{ marginRight: '10px' }}/>
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCameraCancel} />
            </div>
        )
    }

    render(){  
        // console.log(this.state.imgSrc)  
        const buttonFooter=this.renderButtons();
        const cameraButtonFooter = this.renderCameraButtons();
        if(this.state.redirectSupportCases) {
            return(
                <Redirect to='/supportcases' />
            );
        } else {
            return(
                <div>
                    <div className="p-grid">
                        <div className="p-col">
                            {this.state.imgSrc[0] ? 
                                (
                                    <Card style={{ overflow:'hidden', width:'20vw' }} 
                                    // footer={cardFooter1}
                                    >
                                        <div style={{ overflow:'hidden' }}><img alt='file' 
                                            src={this.state.imgSrc[0]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} 
                                            // onClick={() => this.editImage(0)} 
                                            />
                                        </div>
                                    </Card>
                                ) : 
                                (
                                    <Button type='button' label='Choose Image' icon='pi pi-plus' onClick={() => this.openChooseDialog("file1")} />
                                )
                            }
                        </div>
                        <div className="p-col">
                            {this.state.imgSrc[1] ? 
                                (
                                    <Card style={{ overflow:'hidden', width:'20vw' }} 
                                    // footer={cardFooter1}
                                    >
                                        <div style={{ overflow:'hidden' }}><img alt='file' 
                                            src={this.state.imgSrc[1]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} 
                                            // onClick={() => this.editImage(0)} 
                                            />
                                        </div>
                                    </Card>
                                ) : 
                                (
                                    <Button type='button' label='Choose Image' icon='pi pi-plus' onClick={() => this.openChooseDialog("file2")} />
                                )
                            }
                        </div>
                        <div className="p-col">
                            {this.state.imgSrc[2] ? 
                                (
                                    <Card style={{ overflow:'hidden', width:'20vw' }} 
                                    // footer={cardFooter1}
                                    >
                                        <div style={{ overflow:'hidden' }}><img alt='file' 
                                            src={this.state.imgSrc[2]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} 
                                            // onClick={() => this.editImage(0)} 
                                            />
                                        </div>
                                    </Card>
                                ) : 
                                (
                                    <Button type='button' label='Choose Image' icon='pi pi-plus' onClick={() => this.openChooseDialog("file3")} />
                                )
                            }
                        </div>
                        <div className="p-col">
                            {this.state.imgSrc[3] ? 
                                (
                                    <Card style={{ overflow:'hidden', width:'20vw' }} 
                                    // footer={cardFooter1}
                                    >
                                        <div style={{ overflow:'hidden' }}><img alt='file' 
                                            src={this.state.imgSrc[3]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} 
                                            // onClick={() => this.editImage(0)} 
                                            />
                                        </div>
                                    </Card>
                                ) : 
                                (
                                    <Button type='button' label='Choose Image' icon='pi pi-plus' onClick={() => this.openChooseDialog("file4")} />
                                )
                            }
                        </div>
                        <div className="p-col">
                            {this.state.imgSrc[4] ? 
                                (
                                    <Card style={{ overflow:'hidden', width:'20vw' }} 
                                    // footer={cardFooter1}
                                    >
                                        <div style={{ overflow:'hidden' }}><img alt='file' 
                                            src={this.state.imgSrc[4]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} 
                                            // onClick={() => this.editImage(0)} 
                                            />
                                        </div>
                                    </Card>
                                ) : 
                                (
                                    <Button type='button' label='Choose Image' icon='pi pi-plus' onClick={() => this.openChooseDialog("file5")} />
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <Dialog visible={this.state.openChooseDialogState} footer={buttonFooter} onHide={this.handleCancel} baseZIndex={1300} maximized blockScroll focusOnShow={false} closable={false}>
                        <div style={{width:'99vw', maxWidth:'100%'}}>
                            <Button type='button' label='Capture Image' icon='pi pi-camera' onClick={this.openCameraDialog} />
                            <FileUpload name={this.state.uploadPath} key={this.state.inputKey} 
                                    customUpload uploadHandler={this.saveBeforeUpload} 
                                    mode="basic" auto chooseLabel="Choose Image"/>
                            </div>
                        </Dialog>
                        <Dialog visible={this.state.cameraDialog} footer={cameraButtonFooter} onHide={this.handleCancel} baseZIndex={1300} maximized blockScroll focusOnShow={false} closable={false}>
                            <div style={{width:'99vw', maxWidth:'100%'}}>
                                <div><BrowserView><Webcam audio={false} ref={this.setWebcamRef} screenshotFormat="image/jpeg" style={{maxWidth: '300px', maxHeight:'400px'}}/></BrowserView></div>
                                <div><MobileView><Webcam videoConstraints={videoConstraints} audio={false} ref={this.setWebcamRef} screenshotFormat="image/jpeg" style={{maxWidth: '300px', maxHeight:'400px'}}/></MobileView></div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            );
        }
    }
}

export default TicketFilesComponent;