import React from 'react';

import { TableService } from '../services/FetchServices';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {MultiSelect} from 'primereact/multiselect';
import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { CSVLink } from "react-csv";

class LazyDatatable extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            columnWidthTracker: this.props.columnWidthTracker,
            csvRecords: [],
            numRows: 10,
            lazyParams: {
                first: 0,
                rows: 10,
                page: 1
            }
        }
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onSystemDropdownChange = this.onSystemDropdownChange.bind(this);
        this.onKeyLevelDropdownChange = this.onKeyLevelDropdownChange.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.onColumnToggle = this.onColumnToggle.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleCsvExport = this.handleCsvExport.bind(this);
        this.showResults = this.showResults.bind(this);
        // this.dataCsvExport = this.dataCsvExport.bind(this);
        this.handleStateSave = this.handleStateSave.bind(this);
        this.onStateRestore = this.onStateRestore.bind(this);
        this.sleep = this.sleep.bind(this);
        this.handleRestoreState = this.handleRestoreState.bind(this);
        this.csvLinkEl = React.createRef();
    }

    async componentDidMount() {
        let parsedTableData = JSON.parse(sessionStorage.getItem(this.props.tableData));
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let lazyParams;

        if(parsedTableData!==null) {
            if(parsedTableData.columnWidths===null) {
                this.props.handleWidth("146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3");
            } else {
                this.props.handleWidth(parsedTableData.columnWidths);
            }

            if(parsedTableData.rows!==null) {
                lazyParams = { ...this.state.lazyParams, orgID: parsedUserData.uiU_M1ORGID, rows: parsedTableData.rows };
            } else {
                lazyParams = { ...this.state.lazyParams, orgID: parsedUserData.uiU_M1ORGID };
            }
        } else {
            lazyParams = { ...this.state.lazyParams, orgID: parsedUserData.uiU_M1ORGID };
        }

        this.setState({ lazyParams });

        let parsedFilterData = JSON.parse(sessionStorage.getItem(this.props.filterData));
        
        if(parsedFilterData !== null) {
            this.setState({isFilterData: true})
        } else {
            this.setState({isFilterData: false})
        }

        this.props.loadLazyData(lazyParams);
    }

    onColumnToggle(event) {
        event.preventDefault();
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.props.columnArray.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.props.handleSelectedColumns(orderedSelectedColumns);

        switch (event.value.length) {
            case 0:
                this.props.handleSelectedColumns(this.props.columnArray);
                break;
            case 1:
                this.props.handleWidth("1463");
                break;
            case 2:
                this.props.handleWidth("731.5,731.5");
                break;
            case 3:
                this.props.handleWidth("487.6,487.6,487.6");
                break;
            case 4:               
                this.props.handleWidth("365.75,365.75,365.75,365.75");
                break;
            case 5:
                this.props.handleWidth("292.6,292.6,292.6,292.6,292.6");
                break;
            case 6:
                this.props.handleWidth("243.9,243.9,243.9,243.9,243.9,243.9");
                break;
            case 7:
                this.props.handleWidth("209,209,209,209,209,209,209");
                break;
            case 8:
                this.props.handleWidth("182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875");
                break;
            case 9:
                this.props.handleWidth("162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5");
                break;
            case 10:
                this.props.handleWidth("146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3");
                break;
            case 11:
                this.props.handleWidth("133,133,133,133,133,133,133,133,133,133,133");
                break;
            case 12:
                this.props.handleWidth("121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91");
                break;
            default:
                console.log('Width Error, general datatable, line 111');
        }
    }

    handleReorder(e) {
        switch (e.columns.length) {
            case 0:
                this.props.handleSelectedColumns(this.props.columnArray);
                break;
            case 1:
                this.props.handleWidth("1463");
                break;
            case 2:
                this.props.handleWidth("731.5,731.5");
                break;
            case 3:
                this.props.handleWidth("487.6,487.6,487.6");
                break;
            case 4:               
                this.props.handleWidth("365.75,365.75,365.75,365.75");
                break;
            case 5:
                this.props.handleWidth("292.6,292.6,292.6,292.6,292.6");
                break;
            case 6:
                this.props.handleWidth("243.9,243.9,243.9,243.9,243.9,243.9");
                break;
            case 7:
                this.props.handleWidth("209,209,209,209,209,209,209");
                break;
            case 8:
                this.props.handleWidth("182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875");
                break;
            case 9:
                this.props.handleWidth("162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5");
                break;
            case 10:
                this.props.handleWidth("146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3");
                break;
            case 11:
                this.props.handleWidth("133,133,133,133,133,133,133,133,133,133,133");
                break;
            case 12:
                this.props.handleWidth("121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91");
                break;
            default:
                console.log('Width Error, general datatable, line 157');
        }        
    }

    handleResize(e) {
        let delta = e.delta;
        let tempString='';
        let tempArray=[];
        let tempWidthValue=0;
        if(delta>0 || delta<0) {
            tempString = this.props.columnWidthTracker;
            tempArray = tempString.split(',');
            tempArray[e.element.cellIndex] = e.element.clientWidth.toString();
            tempWidthValue = parseFloat(tempArray[e.element.cellIndex + 1]);
            tempWidthValue = tempWidthValue - delta;
            tempArray[e.element.cellIndex + 1] = tempWidthValue.toString();
            tempArray = tempArray.toString();
            this.props.handleWidth(tempArray);
        } else {
            console.log('Resize Error, General Datatable, line 258')
        }
    }

    // handleChange(data) {
    //     this.setState({ csvRecords: data })
    // }



    onPage(event) {
        let lazyParams = { ...this.state.lazyParams, ...event };
        lazyParams['page'] = event.page + 1;
        this.setState({ lazyParams });
        this.props.loadLazyData(lazyParams);
    }

    onSort(event) {
        let lazyParams = { ...this.state.lazyParams, ...event };
        this.setState({ lazyParams });
        this.props.loadLazyData(lazyParams);
    }

    onFilter(event) {
        let lazyParams = { ...this.state.lazyParams, ...event };
        lazyParams['first'] = 0;
        // this.setState({ lazyParams }, this.loadLazyData);
        this.setState({ lazyParams });
    }

    handleApplyFilters() {
        this.props.loadLazyData(this.state.lazyParams);
    }

    onSystemDropdownChange(e) {
        this.dt.filter(e.value, 'system', 'in');
        this.setState({selectedSystems: e.value});
    }

    onKeyLevelDropdownChange(e) {
        this.dt.filter(e.value, 'keyLevel', 'in');
        this.setState({selectedKeyLevels: e.value});
    }

    itemTemplate(option) {
        return (
            <div>{option.label}</div>
        )
    }

    handleStateSave() {
        if(this.dt !== null) {
            if(this.dt.state.filters !== null) {
                let filterData = this.dt.state.filters;
                sessionStorage.setItem(this.props.filterData, JSON.stringify(filterData));
            }
        } 
    }

    async onStateRestore(e) {
        // if(window.screen.width>1250&&window.screen.width<1500) {
        //     let tempArray = e.selectedColumns;        
        //     await this.sleep(100);
        //     this.props.handleSelectedColumns([tempArray.pop()])
        //     await this.sleep(100);
        //     this.props.handleSelectedColumns(this.props.columnArray)
        // }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    handleRestoreState() {
        let userData = JSON.parse(sessionStorage.getItem("userData"));
        let x = JSON.parse(sessionStorage.getItem(this.props.tableData));
        let filterData = JSON.parse(sessionStorage.getItem(this.props.filterData));
        if(filterData&&x!==null) {
            let newTable = {
                "orgID": x.orgID,
                "user_ID": x.user_ID,
                "page_ID": x.page_ID,
                "rows": x.rows,
                "columnOrder": x.columnOrder,
                "columnWidths": x.columnWidths,
                "filters": filterData,
                "selectedColumns": x.selectedColumns
            }
            sessionStorage.setItem(this.props.tableData, JSON.stringify(newTable));
            let newTableData = JSON.parse(sessionStorage.getItem(this.props.tableData));
            let lazyParams = { ...this.state.lazyParams };
            lazyParams['rows'] = x.rows;
            this.setState({ lazyParams });
            return(newTableData);
        } else if(x===null) {
            let newUsers = {
                "orgID": userData.uiU_M1ORGID,
                "user_ID": userData.uiU_ID,
                "page_ID": this.props.pageNumber,
                "rows": 10,
                "columnOrder": this.props.defaultOrder,
                "columnWidths": this.props.columnWidthTracker,
                "selectedColumns": this.props.columnArray
            }
            return(newUsers)
        } else {
            let lazyParams = { ...this.state.lazyParams };
            lazyParams['rows'] = x.rows;
            this.setState({ lazyParams });
            return(x);
        }
    }

    handleSave = async e => {
        e.preventDefault();
        let userData = JSON.parse(sessionStorage.getItem('userData'));
        let filterData = JSON.parse(sessionStorage.getItem(this.props.filterData));
        if(this.dt.state.columnOrder===undefined) {
            await TableService.saveTableState(userData.uiU_M1ORGID, userData.uiU_ID, this.props.pageNumber, this.state.lazyParams.rows, this.props.columnWidthTracker, this.props.defaultOrder, this.props.selectedColumns).then(data => this.showResults(data));
            let newTable = {
                "orgID": userData.uiU_M1ORGID,
                "user_ID": userData.uiU_ID,
                "page_ID": this.props.pageNumber,
                "rows": this.state.lazyParams.rows, 
                "columnOrder": this.props.defaultOrder,
                "columnWidths": this.props.columnWidthTracker,
                "filters": filterData,
                "selectedColumns": this.props.selectedColumns
            }
            sessionStorage.setItem(this.props.tableData, JSON.stringify(newTable));
        } else {
            await TableService.saveTableState(userData.uiU_M1ORGID, userData.uiU_ID, this.props.pageNumber, this.state.lazyParams.rows, this.props.columnWidthTracker, this.dt.state.columnOrder, this.props.selectedColumns).then(data => this.showResults(data));
            let newTable = {
                "orgID": userData.uiU_M1ORGID,
                "user_ID": userData.uiU_ID,
                "page_ID": this.props.pageNumber,
                "rows": this.state.lazyParams.rows,
                "columnOrder": this.dt.state.columnOrder,
                "columnWidths": this.props.columnWidthTracker,
                "filters": filterData,
                "selectedColumns": this.props.selectedColumns
            }
            sessionStorage.setItem(this.props.tableData, JSON.stringify(newTable));
        }
    }

    showResults(data) {
        if(data) {
            this.messages.show({severity: 'success', summary: 'Successfully Saved', life:1000});
        } else {
            this.messages.show({severity: 'error', summary: 'Save Unsuccessful', life:1000});
        }
    } 

    // async handleCsvExport() {
    //     console.log("click handler")
    //     let lazyParams = this.state.lazyParams;
    //     lazyParams['rows'] = this.props.totalRecords;
    //     await this.props.loadLazyCsvData(lazyParams);
    //     this.csvLinkEl.current.link.click();
    // }

    async handleCsvExport() {
        console.log("click handler")
        let lazyParams = this.state.lazyParams;
        lazyParams['rows'] = this.props.totalRecords;
        await this.props.loadLazyCsvData(lazyParams);
        this.csvLinkEl.current.link.click();
    }

    renderHeader() {
        return (
            <div className='p-grid'>
                <div className='p-col-12 tbPadding'>
                    <div style={{float:'left'}} >
                        <div className='table-title' style={{float:'left', marginRight:'10px'}}>{this.props.tableName}</div>
                        <MultiSelect style={{float:'left', marginRight:'10px'}} value={this.props.selectedColumns} options={this.props.columnArray} optionLabel='header' onChange={this.onColumnToggle} fixedPlaceholder placeholder={<div style={{paddingTop: '0.429rem'}}>Selected Columns</div>}/>
                        {/* <InputText style={{float:'left'}} type ='search' onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder='Search' /> */}
                    </div>
                    <div style={{float:'right'}}>
                        <div style={{float:'left', marginRight:'10px'}}>
                            <span>
                                {/* <Button style={{marginRight:'10px'}} onClick={this.handleCsvExport} type='button' icon='pi pi-external-link' iconPos='left' label='CSV Export'/>
                                <CSVLink 
                                    data={this.props.csvRecords}
                                    ref={this.csvLinkEl}
                                    headers={this.props.csvHeader}
                                    filename={this.props.exportFileName}
                                    style={{display: 'none'}}
                                />  */}
                                <Button style={{marginRight:'10px'}} onClick={this.handleCsvExport} type='button' icon='pi pi-external-link' iconPos='left' label='CSV Export'/>
                                <CSVLink 
                                    data={this.props.csvRecords}
                                    ref={this.csvLinkEl}
                                    headers={this.props.csvHeader}
                                    filename={this.props.exportFileName}
                                    style={{display: 'none'}}
                                /> 
                            </span>
                            <Button type='button' icon='pi pi-filter' iconPos='left' label='Apply Filters' onClick={this.handleApplyFilters} />
                        </div>
                        <div style={{float:'left'}}>
                            <Button type='button' icon='pi pi-save' iconPos='left' label='Save Layout' onClick={this.handleSave} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        // console.log(this.props.records)

        const header=this.renderHeader();

        const systemFilter = <MultiSelect itemTemplate={this.itemTemplate} value={this.state.selectedSystems} 
            options={this.props.systemDropdownOptions} onChange={(e) => this.onSystemDropdownChange(e)} 
            placeholder="All" className="p-column-filter-multi" />

        const levelFilter = <MultiSelect itemTemplate={this.itemTemplate} value={this.state.selectedKeyLevels} 
            options={this.props.keyLevelDropdownOptions} onChange={(e) => this.onKeyLevelDropdownChange(e)} 
            placeholder="All" className="p-column-filter-multi" />

        const columnComponents = this.props.selectedColumns.map(col=> {
            if(col.field==='system') {
                return <Column field={col.field} header={col.header} sortable filter filterElement={systemFilter}  style={{textAlign: 'left', fontSize:'small', margin:'0px'}}/>
            }  else if(col.field==='keyLevel') {
                return <Column field={col.field} header={col.header} sortable filter filterElement={levelFilter}  style={{textAlign: 'left', fontSize:'small', margin:'0px'}}/>
            } else if(col.field==='keyMarkID' || col.field==='coreMarkID' || col.field==='keysCut' || col.field==='coresPinned') {
                return <Column key={col.field} field={col.field} responsive header={col.header} sortable filter filterMatchMode="contains" filterPlaceholder={col.header} style={{textAlign: 'center', fontSize:'small', margin:'0px'}} />;
            } else {
                return <Column key={col.field} field={col.field} responsive header={col.header} sortable filter filterMatchMode="contains" filterPlaceholder={col.header} style={{textAlign: 'left', fontSize:'small', margin:'0px'}} />;
            }
        })
            
        return(
            <div className='p-shadow-2' style={{maxWidth:'100%'}}>
                <Messages style={{textAlign:'center'}} ref={(el) => this.messages = el} />
                <BrowserView>
                    <DataTable ref={(el) => this.dt = el} value={this.props.records} lazy
                        stateStorage="custom" customSaveState={this.handleStateSave} customRestoreState={this.handleRestoreState} onStateRestore={e => this.onStateRestore(e)}
                        scrollable scrollHeight="72vh"
                        header={header} responsive={true} resizableColumns={true} reorderableColumns={true}
                        className="p-datatable-striped p-datatable-sm" columnResizeMode="fit" dataKey={this.props.dataKey}
                        onColumnResizeEnd={e => this.handleResize(e)} onColReorder={e => this.handleReorder(e)}
                        onRowClick={e => this.props.handleRowClick(e)}
                        // globalFilter={this.state.globalFilter}
                        emptyMessage="No records found" 
                        exportFilename='records'
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        paginator first={this.state.lazyParams.first} totalRecords={this.props.totalRecords} onPage={this.onPage}
                        rows={this.state.lazyParams.rows} rowsPerPageOptions={[10, 25, 50]} rowHover 
                        // onValueChange={filteredData => this.handleChange(filteredData)}
                        onSort={this.onSort} sortField={this.state.lazyParams.sortField} sortOrder={this.state.lazyParams.sortOrder}
                        onFilter={this.onFilter} filters={this.state.lazyParams.filters} 
                        loading={this.props.dataLoading}
                        >
                        {columnComponents}
                    </DataTable>
                </BrowserView>
            </div>
        );
    }
}

export default LazyDatatable;