import React from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { BlobServiceClient } from "@azure/storage-blob";
import { BlobService } from '../services/BlobServices';
import { Button } from 'primereact/button';
import * as CryptoJS from 'crypto-js'; 
import { v4 as uuidv4 } from 'uuid'; 
import { ProgressSpinner } from 'primereact/progressspinner';

import { serverAddr } from '../services/serverAddr';
import { KeyService } from '../services/FetchServices';

// Create styles
const styles = StyleSheet.create({
  container: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginTop: '30px'
  },
  image: {
    height: 62,
    width: 150
  },
  userImg: {
    height: 100,
    width: 100
  },
  imageView: {
    display:'flex',
    flexDirection:'column',
    flexBasis: '100%',
    flex: 1
  },
  imageView2: {
    display:'flex',
    flexDirection:'column',
    flexBasis: '100%',
    flex: 2
  },
  detailContainer: {
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap',
    width: '100%',
    marginBottom:'15px'
  },
  ImageContainer: {
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap',
    width: '100%',
    justifyContent:'space-around',
    marginTop:'30px',
    marginBottom:'30px'
  },
  detailContainerBottom: {
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap',
    width: '100%',
    marginBottom: '30px'
  },
  detailItem: {
    display:'flex',
    flexDirection:'column',
    flexBasis: '100%',
    flex: 1
  },
  detailHead: {
    fontWeight: 'bold'
  },
  detailText: {
    fontSize: 'small',
    fontWeight:'normal'
  },
  borderContainer: {
    border:'1px solid black',
    padding: '5px',
    marginBottom:'20px'
  },
  paragraphs: {
    marginBottom:'20px'
  }
});

class KeyHandOutReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: null,
      fileName: null,
      file: null
    }
}
  async componentDidMount () {            
    if (this.props){
      const receipt = this.receiptBuilder();
      
      if (receipt){
        await this.saveHandOutInfToSvr(receipt);         
        await this.uploadKeyHandOuts();
        
        if(this.props.fields.permanentIssueFlag===1) {
          await KeyService.IssueKey(this.props.orgID, true, this.props.uiY_ID, '12/20/2121', "Employee", this.props.currUserID, this.state.fileName )
        } else {
          let returnDateVar = this.props.fields.keyReturnDate.toLocaleDateString("en-US", {day:'numeric', month:'numeric', year:'numeric'})
          await KeyService.IssueKey(this.props.orgID, false, this.props.uiY_ID, returnDateVar, "Employee", this.props.currUserID, this.state.fileName )
        }
      }      
    }    
  }

  receiptBuilder = (blob) => { 
    return (
      <Document>
        <Page key={this.props.fields.selectedKey} size="A4" orientation="portrait" wrap={false}>            
          <View>
            <Text style={styles.title}>Key Hand Out Reciept</Text>                
            <View style={styles.container}>
              <View style={styles.borderContainer}>
                <View style={styles.detailContainer}>
                  <View style={styles.detailItem}><Text style={styles.detailHead}>{'Organization:'}</Text><Text styles={styles.detailText}>{this.props.orgID}</Text></View>
                  <View style={styles.detailItem}><Text style={styles.detailHead}>Issued to:</Text><Text styles={styles.detailText}>{this.props.currUserFN}  {this.props.currUserLN}</Text></View>
                </View>
                <View style={styles.detailContainer}>
                  <View style={styles.detailItem}><Text style={styles.detailHead}>Date:</Text><Text styles={styles.detailText}>{this.props.fields.currentDateTime}</Text></View>
                </View>
                <View style={styles.detailContainer}>
                  <View style={styles.detailItem}><Text style={styles.detailHead}>System:</Text><Text styles={styles.detailText}>{this.props.systemName}</Text></View>
                  <View style={styles.detailItem}><Text style={styles.detailHead}>Key:</Text><Text styles={styles.detailText}>{this.props.selectedKey}</Text></View>
                </View>
                <View style={styles.detailContainerBottom}>
                  {this.props.fields.permanentIssueFlag ? 
                      <View style={styles.detailItem}><Text style={styles.detailHead}>Return Date:</Text><Text styles={styles.detailText}>Key Is Permanently Issued</Text></View>
                    :
                      <View style={styles.detailItem}>
                        <Text style={styles.detailHead}>Return Date:</Text>
                        <Text styles={styles.detailText}>
                          {`${new Date(this.props.fields.keyReturnDate).toLocaleDateString()}`} (Days Remaining: {this.props.fields.returnInNDays})
                        </Text>
                      </View>
                    }
                </View>
              </View>
              <View>
                <Text style={styles.paragraphs}>By acceptance of the above key, I assume full responsibility for the keeping and safeguarding of the key. If any key is lost or stolen, I must report the 
                  incident in writing to my immediate supervisor immediately.
                </Text>
                <Text style={styles.paragraphs}>By signature, I acknowledge I have read the above rules and acknowledge the receipt of the above key:</Text>
                <View style={styles.ImageContainer}>
                  <View style={styles.imageView2}><Image src={this.props.fields.signatureDataURL} style={styles.image} /></View>
                  <View style={styles.imageView}><Image src={this.props.finalImg} style={styles.userImg}/></View>
                </View>
              </View>
              <View>
                <View style={styles.paragraphs}><Text style={styles.detailHead}>Date issued:</Text><Text styles={styles.detailText}>{this.props.fields.currentDateTime}</Text></View>
                <View><Text style={styles.detailHead}>Issued By:</Text><Text styles={styles.detailText}>{this.props.issuerName}</Text></View>
              </View>
            </View>
          </View>
        </Page>
      </Document> 
    );
  }   

uploadKeyHandOuts = async () => {   
    /* SAS Key Gen BEGIN */     
  const end = new Date(new Date().getTime() + (30 * 60 * 1000));
  const signedPermissions = 'rwdlac';
  const signedService = 'b';
  const signedResourceType = 'sco';
  const signedExpiry = end.toISOString().substring(0, end.toISOString().lastIndexOf('.')) + 'Z';
  const signedProtocol = 'https';
  const signedVersion = '2020-02-10';  
  const StringToSign =
      process.env.REACT_APP_ACCOUNT_NAME + '\n' +
      signedPermissions + '\n' +
      signedService + '\n' +
      signedResourceType + '\n\n'  +
      signedExpiry + '\n\n'+
      signedProtocol + '\n' +
      signedVersion + '\n';

  var str =CryptoJS.HmacSHA256(StringToSign,CryptoJS.enc.Base64.parse(process.env.REACT_APP_ACCOUNT_KEY));
  var sig = CryptoJS.enc.Base64.stringify(str);     

  const sas =`sv=${(signedVersion)}&ss=${(signedService)}&srt=${(signedResourceType)}&sp=${(signedPermissions)}&se=${encodeURIComponent(signedExpiry)}&spr=${(signedProtocol)}&sig=${encodeURIComponent(sig)}`;
  /* SAS Key Gen END */
  
  const account = "midwestblob";
  const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net/?${sas}`);
  const containerName = serverAddr.blobContainer + "-keyhandout-files"
  const containerClient = blobServiceClient.getContainerClient(containerName);
  let content = await pdf(this.state.file).toBlob();
  const blobName = `${this.state.fileName}`
  const blobOptions = { blobHTTPHeaders: { blobContentType: 'application/pdf' } };
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  
  try {  
      await blockBlobClient.upload(content, content.size, blobOptions);

      //fetch blob to verify it was stored
      await BlobService.downloadBlob(containerName, blobName);

      this.setState({loading: false});
  }catch(e){
    alert('This reciept was not saved to storage! Error Message:' + e)
    this.setState({loading: false});
     console.log(e);   //need to refine to an error output log etc.., 
  }
}

saveHandOutInfToSvr = async (blob) => {
  
  //TODO: we need to decide on archival process before continuing 12 Aug 2021
  
  this.setState({
    fileName: this.props.orgID + uuidv4() + ".pdf",      
    file: blob,    
  });

  
}

/*============================================================================================================*/
  render(){ 
    return(
      <div>     
          {this.state.loading &&
            <div className="p-jc-center"><ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="2" animationDuration=".5s" /></div>
          }     
          <div className="p-grid p-justify-center">
            <div className="p-col-12 p-grid p-justify-center">                
              <h3 className='p-col-12' style={{marginBottom: '30px'}}>Key Hand Out Reciept</h3>
                <div className='p-col-11 p-grid' style={{border: '1px solid black'}}>
                  <div className='p-col-12 p-lg-4'><h3>Organization: </h3>{this.props.orgID}</div>
                  <div className="p-col-12 p-lg-4"><h3>Issued to: </h3> {this.props.currUserFN}  {this.props.currUserLN}</div>
                  <div className="p-col-12 p-lg-4"><h3>Date: </h3> {this.props.fields.currentDateTime}</div>
                  <div className='p-col-12 p-lg-4'><h3>System: </h3> {this.props.systemName}</div>
                  <div className="p-col-12 p-lg-4"><h3>Key: </h3> {this.props.selectedKey}</div>                         
                  {this.props.fields.permanentIssueFlag ? 
                    <div className="p-col-12 p-lg-4"><h3>Return Date:</h3>Key is permanently issued</div>
                  :
                    <div className="p-col-12 p-lg-4"><h3>Return Date:</h3> {`${new Date(this.props.fields.keyReturnDate).toLocaleDateString()}`} 
                      (Days Remaining: {this.props.fields.returnInNDays})
                    </div>
                  }                
                </div>
                <h3 className='p-col-12' style={{marginTop:'50px'}}>
                  <strong>
                      By acceptance of the above key, I assume full responsibility for the keeping and safeguarding of the key.
                      If any key is lost or stolen, I must report the incident in writing to my immediate supervisor immediately. 
                  </strong>
                </h3>   
              </div> 
              <div className="p-col-12">
                <div className="box">        
                <h3>By signature, I acknowledge I have read the above rules and acknowledge the receipt of the above key:</h3>    
                  <img src={this.props.fields.signatureDataURL} alt="" />
                </div>                
              </div>
              <div className="p-col-12 p-lg-6"><h3>Date issued:</h3> {this.props.fields.currentDateTime}</div>
              <div className='p-col-12 p-lg-6'><h3>Issued By:</h3> {this.props.issuerName}</div>
          </div>
          <div className='p-grid p-justify-center'>
            {this.props && 
              <PDFDownloadLink 
                fileName={this.state.fileName}
                document={this.state.file}>
                {({ blob, url, loading, error }) =>
                loading ? <div className='p-col-12 p-lg-2' style={{minWidth:'100%'}}><Button label='Loading PDF...' /></div> : <div className='p-col-12 p-lg-2' style={{minWidth:'100%'}}><Button icon='pi pi-download' label='Download PDF'/></div>
                }
              </PDFDownloadLink>               
            }
            <div className='p-col-12 p-lg-2' style={{marginLeft:'10px', marginBottom:'30px'}}><Button label='Issue Another Key' icon='pi pi-refresh' onClick={this.props.handleRefresh} /></div>
          </div>
      </div>
    );
  }
}

export default KeyHandOutReceipt