import React from 'react';

import {Steps} from 'primereact/steps';
import {Button} from 'primereact/button';
import { Card } from 'primereact/card';

import { KeyService } from '../services/FetchServices';
import KeyHandInTable from '../components/KeyHandInTable';
import { serverAddr } from '../services/serverAddr';
import { BlobService } from '../services/BlobServices';

import * as CryptoJS from 'crypto-js'; 
import { BlobServiceClient } from "@azure/storage-blob";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import { v4 as uuidv4 } from 'uuid'; 

// Create styles
const styles = StyleSheet.create({
    container: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: '20px',
      marginTop: '30px',
    },  
    detailContainer: {
      display:'flex',
      flexDirection:'row',
      flexWrap:'wrap',
      width: '100%',
      marginBottom:'15px'
    },
    detailItem: {
      display:'flex',
      flexDirection:'column',
      flexBasis: '100%',
      flex: 1
    },
    detailHead: {
      fontWeight: 'bold'
    },
    detailText: {
      fontSize: 'small',
      fontWeight:'normal'
    },
    borderContainer: {
      border:'1px solid black',
      padding: '5px',
      marginBottom:'20px'
    }
  });

/*=================================================================================*/

class KeyHandInComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            activeIndex: 0,            
            peopleData: [],     
            keyData:[], 
            currUserFN: null,
            currUserLN: null,
            currUserID: null,
            selectedKey: null,      
            globalFilter: null,
            isLoading: true,
            secRole: '',
            disabled: false,
            fields: {},
            receiptReady: false,
            cabName: '',
            hookNum: ''
        };

        this.items = [
            {label: 'Key'},
            {label: 'Confirm'}
        ];

        this.setActiveIndex = this.setActiveIndex.bind(this);
        this.handleCancel = this.handleCancel.bind(this); 
        this.fetchKeysMethod = this.fetchKeysMethod.bind(this);
    }

    async componentDidMount() {
        let x = JSON.parse(sessionStorage.getItem("peopleTableData"));        
        if(x !== null) {
            if(x.selectedColumns !== null) {
                this.setState({ selectedColumns: x.selectedColumns })
            }
        }
        
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let name=parsedUserData.uiU_FN + ' ' + parsedUserData.uiU_LN;

        if(parsedUserData.secString[13]==='2') {
            this.setState({ disabled: true })
        }

        this.setState({
            secRole: parsedUserData.secString[13],
            activeIndex: 0,
            orgName: (parsedUserData.uiU_M1ORGID.toLowerCase()),
            issuerName: name,
            issuerID: parsedUserData.uiU_ID,
        })

        this.fetchKeysMethod();
    }

    async fetchKeysMethod() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await KeyService.fetchIssuedKeys( parsedUserData.uiU_M1ORGID, -1, 0, 0 )
        .then(data => this.setState({keyData: data.keylist,  isLoading: false}))
    }

    setActiveIndex(e) {        
        switch(e.index){
            case 1:
                if(this.state.selectedKey){
                    this.setState({activeIndex: e.index})
                } else if (this.state.currUserFN) {
                    this.setState({activeIndex: 1})
                } else {
                    this.setState({activeIndex: 0})
                }
                break;
            case 2:
                break;
            default :
                this.setState({activeIndex: e.index})
                break
        }       
    }

    handleCancel() {
        this.fetchKeysMethod();
        this.setState({
            activeIndex: 0,
            currKeyID: '',
            system: '',
            fullCode: '',
            cabname: '',
            hookNum: '',
            date: '',
            employeeID: '',
            file: '',
            fileName:'',
            keyHolder: ''
        });        
    }

    handleNextStep = async (inbound, idx) => {       
       if (idx === 1){
            let currKeyID = inbound.curr.data.uiY_ID;
            let system = inbound.curr.data.uiY_SYSTEM;
            let fullCode = inbound.curr.data.uiY_FULLCODE;
            let cabname = inbound.curr.data.cabname;
            let hookNum = inbound.curr.data.uiY_HOOKNUMBER;
            let employeeID = inbound.curr.data.uiY_EMPLOYEEID;
            let keyHolder = inbound.curr.data.fullname
            const dateVar = new Date();
            if(hookNum===0) {
                this.setState({
                    activeIndex: idx,
                    currKeyID: currKeyID,
                    system: system,
                    fullCode: fullCode,
                    employeeID: employeeID,
                    keyHolder: keyHolder,
                    date: dateVar.toLocaleString("en-US", {weekday:'short', month:'short', day:'numeric', year:'numeric', hour:'numeric', minute:'numeric', second:'numeric', timeZoneName:'short'}),
                }); 
            } else {
                this.setState({
                    activeIndex: idx,
                    currKeyID: currKeyID,
                    system: system,
                    fullCode: fullCode,
                    cabname: cabname,
                    hookNum: hookNum,
                    employeeID: employeeID,
                    keyHolder: keyHolder,
                    date: dateVar.toLocaleString("en-US", {weekday:'short', month:'short', day:'numeric', year:'numeric', hour:'numeric', minute:'numeric', second:'numeric', timeZoneName:'short'}),
                }); 
            }  
       } if(idx===2) {
            this.setState({isLoading: true})
            const receipt = this.receiptBuilder();
            await this.saveHandOutInfToSvr(receipt);         
            await this.uploadKeyHandOuts();
            await KeyService.ReturnKey(this.state.orgName, this.state.currKeyID, this.state.fileName, this.state.employeeID, this.state.issuerID)
            this.setState({
                activeIndex: idx,
                isLoading: false
            })
       }       
    }

    receiptBuilder = (blob) => { 
        return (
          <Document>
            <Page key={this.state.currKeyID} size="A4" orientation="portrait" wrap={false}>            
              <View>
                <Text style={styles.title}>Key Hand In Reciept</Text>                
                <View style={styles.container}>
                    <View style={styles.borderContainer}>
                        <View style={styles.detailContainer}>
                            <View style={styles.detailItem}><Text style={styles.detailHead}>{'Organization:'}</Text><Text styles={styles.detailText}>{this.state.orgName}</Text></View>
                            <View style={styles.detailItem}><Text style={styles.detailHead}>Key Recieved By:</Text><Text styles={styles.detailText}>{this.state.issuerName}</Text></View>
                        </View>
                        <View style={styles.detailContainer}>
                            <View style={styles.detailItem}><Text style={styles.detailHead}>System:</Text><Text styles={styles.detailText}>{this.state.system}</Text></View>
                            <View style={styles.detailItem}><Text style={styles.detailHead}>Key:</Text><Text styles={styles.detailText}>{this.state.fullCode}</Text></View>
                        </View>
                        <View style={styles.detailContainer}>
                            <View style={styles.detailItem}><Text style={styles.detailHead}>Key Holder:</Text><Text styles={styles.detailText}>{this.state.keyHolder}</Text></View>
                        </View>
                        <View style={styles.detailContainer}>
                            <View style={styles.detailItem}><Text style={styles.detailHead}>Date:</Text><Text styles={styles.detailText}>{this.state.date}</Text></View>
                        </View>
                    </View>
                 </View>
                </View>
            </Page>
          </Document> 
        );
      }   
    
    uploadKeyHandOuts = async () => {    
        /* SAS Key Gen BEGIN */     
        const end = new Date(new Date().getTime() + (30 * 60 * 1000));
        const signedPermissions = 'rwdlac';
        const signedService = 'b';
        const signedResourceType = 'sco';
        const signedExpiry = end.toISOString().substring(0, end.toISOString().lastIndexOf('.')) + 'Z';
        const signedProtocol = 'https';
        const signedVersion = '2020-02-10';  
        const StringToSign =
            process.env.REACT_APP_ACCOUNT_NAME + '\n' +
            signedPermissions + '\n' +
            signedService + '\n' +
            signedResourceType + '\n\n'  +
            signedExpiry + '\n\n'+
            signedProtocol + '\n' +
            signedVersion + '\n';

        var str =CryptoJS.HmacSHA256(StringToSign,CryptoJS.enc.Base64.parse(process.env.REACT_APP_ACCOUNT_KEY));
        var sig = CryptoJS.enc.Base64.stringify(str);     

        const sas =`sv=${(signedVersion)}&ss=${(signedService)}&srt=${(signedResourceType)}&sp=${(signedPermissions)}&se=${encodeURIComponent(signedExpiry)}&spr=${(signedProtocol)}&sig=${encodeURIComponent(sig)}`;
        /* SAS Key Gen END */
        
      const account = "midwestblob";
      const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net/?${sas}`);
      const containerName = serverAddr.blobContainer + "-keyhandin-files"
      const containerClient = blobServiceClient.getContainerClient(containerName);
      let content = await pdf(this.state.file).toBlob();
      const blobName = `${this.state.fileName}`
      const blobOptions = { blobHTTPHeaders: { blobContentType: 'application/pdf' } };
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      
      try {  
          await blockBlobClient.upload(content, content.size, blobOptions);    

          //fetch blob to verify it was stored
          await BlobService.downloadBlob(containerName, blobName);
          this.setState({loading: false});
      }catch(e){
        alert('This reciept was not saved to storage! Error Message:' + e)
        this.setState({loading: false});
         console.log(e);   //need to refine to an error output log etc.., 
      }
    }
    
    saveHandOutInfToSvr = async (blob) => {
      
      //TODO: we need to decide on archival process before continuing 12 Aug 2021
      
      this.setState({
        fileName: this.state.orgName + uuidv4() + ".pdf",      
        file: blob,    
      });
    }

/*=================================================================================*/
    render(){
        const { activeIndex } = this.state;    
        if(this.state.secRole!=='3'&&!this.state.isLoading) {
            return(
                <div>
                    Your Security Role Prevents You From Handing Out / Handing In Keys
                </div>
            );
        } else {
            return(
                <div>
                    <div className="p-d-flex">                                            
                    <Card title="Key Hand In" style={{width:'40%'}}>                       
                        <Steps  model={this.items} 
                                activeIndex={this.state.activeIndex} 
                                onSelect={(e) => this.setActiveIndex(e)} 
                                readOnly={this.state.activeIndex < 2 ? false : true}                                        
                        />
                        {this.state.activeIndex < 2 &&
                            <div className="p-mt-4">
                                <Button icon='pi pi-times' 
                                        label='Cancel' 
                                        onClick={this.handleCancel} />
                            </div>                        
                        }
                    </Card>        
                    <div className="p-p-6 p-mt-4">
                        {activeIndex === 0 ?
                            <h4>Please select the appropriate key to be returned</h4>
                        : activeIndex === 1 ?
                            <h4>Confirm hand in or Cancel to quit</h4>
                        :   <h4>Thank you. The hand in is complete</h4>
                        }                            
                        <i className="pi pi-angle-double-down p-mr-2"></i>
                    </div>                                      
                                                            
                    </div>                
                    <div className='p-shadow-2' style={{marginTop:5}}>
                    { activeIndex === 0 ?                        
                        <KeyHandInTable
                                keyData={this.state.keyData}
                                pageNumber={16}
                                tableName={'Keys'}
                                exportFileName={'Keys.csv'}
                                tableData={'keysTableData'}
                                filterData={'keysFilterData'}
                                isLoading={this.state.isLoading}
                                nextStep={(curr) => this.handleNextStep({curr}, 1)}                                
                                buttonOnClick={() => this.handleSelect('New')}
                                keyStatus={'returned'}
                            />                           
                    : activeIndex === 1 ?
                        <div>
                            {this.state.isLoading ? (<div><i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i></div>) : (
                                <div className='p-grid p-justify-center'>
                                    <h3 className='p-col-12' style={{marginBottom: '30px'}}>Key Hand In Confirmation</h3>
                                    <div className='p-col-11 p-grid' style={{border: '1px solid black'}}>
                                        <div className='p-col-12 p-lg-4'><h3>Organization: </h3>{this.state.orgName}</div>
                                        <div className="p-col-12 p-lg-4"><h3>System: </h3> {this.state.system}</div>
                                        <div className="p-col-12 p-lg-4"><h3>Date: </h3> {this.state.date}</div>
                                        <div className='p-col-12 p-lg-4'><h3>Key: </h3> {this.state.fullCode}</div>
                                        {this.state.cabName==='' ? (null): (<div className="p-col-12 p-lg-4"><h3>Previous Key Storage: </h3> {this.state.cabname}</div>)}
                                        {this.state.hookNum==='' ? (null): (<div className="p-col-12 p-lg-4"><h3>Previous Hook #: </h3> {this.state.hookNum}</div>)}
                                        <div className="p-col-12 p-lg-4"><h3>Assigned To: </h3> {this.state.keyHolder}</div>                                     
                                    </div>
                                    <div><Button style={{margin:'30px'}} label='Confirm Return' icon='pi pi-check' onClick={(curr) => this.handleNextStep({curr}, 2)} /></div>
                                </div>
                            )}
                        </div>
                    :
                    <div className='p-grid p-justify-center'>
                        <h3 className='p-col-12' style={{marginBottom: '30px'}}>Key Hand In Reciept</h3>
                        <div className='p-col-11 p-grid' style={{border: '1px solid black'}}>
                            <div className='p-col-12 p-lg-4'><h3>Organization: </h3>{this.state.orgName}</div>
                            <div className="p-col-12 p-lg-4"><h3>System: </h3> {this.state.system}</div>
                            <div className="p-col-12 p-lg-4"><h3>Date: </h3> {this.state.date}</div>
                            <div className='p-col-12 p-lg-4'><h3>Key: </h3> {this.state.fullCode}</div>
                            {this.state.cabName==='' ? (null): (<div className="p-col-12 p-lg-4"><h3>Previous Key Storage: </h3> {this.state.cabname}</div>)}
                                {this.state.hookNum==='' ? (null): (<div className="p-col-12 p-lg-4"><h3>Previous Hook #: </h3> {this.state.hookNum}</div>)}  
                            <div className="p-col-12 p-lg-4"><h3>Assigned To: </h3> {this.state.keyHolder}</div>                                     
                        </div>

                        <div>
                            <PDFDownloadLink 
                                fileName={this.state.fileName}
                                document={this.state.file}>
                                {({ blob, url, loading, error }) =>
                                loading ? <span className='p-col-2' style={{minWidth:'100%'}}><Button style={{margin:'25px'}} label='Loading PDF...' /></span> : <span className='p-col-2' style={{minWidth:'100%'}}><Button style={{margin:'25px'}} icon='pi pi-download' label='Download PDF'/></span>
                                }
                            </PDFDownloadLink>               
                            <span className='p-col-2' style={{marginLeft:'10px', minWidth:'100%'}}><Button style={{margin:'25px', marginBottom:'30px'}} label='Return Another Key' icon='pi pi-refresh' onClick={this.handleCancel} /></span>
                        </div>
                    </div>                  
                    }
                    </div>
                </div>
            );
        }
    }
}

export default KeyHandInComponent;