import React from 'react';

import SignatureCanvas from 'react-signature-canvas' ;
import {Button} from 'primereact/button';
import { BrowserView, MobileView } from 'react-device-detect';


const dateVar = new Date();

class KeyHandOutSigConfirm extends React.Component {
    constructor(props) {
        super(props);
        
        this.state={            
            isLoading: false,         
            dirty: false,                        
            currentDateTime: dateVar.toLocaleString("en-US", {weekday:'short', month:'short', day:'numeric', year:'numeric', hour:'numeric', minute:'numeric', second:'numeric', timeZoneName:'short'}),
            errorMsg: null
        }                
        

    }
    
    sigPad = {};

    componentDidMount() {
        
    }    

    clear = () => {
        this.sigPad.clear();
        this.setState({dirty: false});
      }

    trim = async () => {
        if (this.sigPad.isEmpty()){
            this.setState({dirty: false,
                           errorMsg: "You must sign to be issued a key"});
        } else {
            let fields = this.props.fields;
            fields.signatureDataURL = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
            fields.currentDateTime = this.state.currentDateTime;             
            this.props.handleChange(fields);
            this.props.submitConfirmation();
            this.setState({dirty: false, errorMsg: null});

        }
    }

    setDirty = () => {
        if (this.sigPad.isEmpty()){
            this.setState({dirty: false});
        } else {            
            this.setState({dirty: true, errorMsg: null});
        }
    }

    render() {              
        return(      
            <div className="p-grid p-justify-center">
                {this.state.errorMsg &&
                    <div>{this.state.errorMsg}</div>
                }
                <div className="p-col-12 p-grid p-justify-center">                
                    <h1 className='p-col-12' style={{marginBottom: '30px'}}>Key Hand Out Reciept</h1>
                    <div className='p-col-11 p-grid' style={{border: '1px solid black'}}>
                        <div className='p-col-12 p-lg-4'><h3>Organization: </h3>{this.props.orgID}</div>
                        <div className="p-col-12 p-lg-4"><h3>Issued to: </h3> {this.props.currUserFN}  {this.props.currUserLN}</div>
                        <div className="p-col-12 p-lg-4"><h3>Date: </h3> {this.state.currentDateTime}</div>
                        <div className='p-col-12 p-lg-4'><h3>System: </h3> {this.props.systemName}</div>
                        <div className="p-col-12 p-lg-4"><h3>Key: </h3> {this.props.selectedKey}</div>
                        {this.props.fields.permanentIssueFlag ? 
                            <div className="p-col-12 p-lg-4"><h3>Return Date: </h3>Key is permanently issued</div>
                        :
                            <div className="p-col-12 p-lg-4"><h3>Return Date:</h3> {`${new Date(this.props.fields.keyReturnDate).toLocaleDateString()}`} 
                                    (Days Remaining: {this.props.fields.returnInNDays})</div>
                        }
                    </div>
                    <h3 className='p-col-12' style={{marginTop:'50px'}}>
                        <strong>
                            By acceptance of the above key, I assume full responsibility for the keeping and safeguarding of the key.
                            If any key is lost or stolen, I must report the incident in writing to my immediate supervisor immediately. 
                        </strong>
                    </h3>              
                </div>   
                
                <div className="p-col-12">
                    <div className="box">        
                        <h3>By signature, I acknowledge I have read the above rules and acknowledge the receipt of the above key:</h3>                   
                        <BrowserView>
                            <SignatureCanvas ref={(ref) => { this.sigPad = ref }}
                                penColor='red'
                                canvasProps={{width: 480, height: 120, className: 'p-shadow-2' }} 
                                onEnd={() => this.setDirty()}
                            />
                        </BrowserView>
                        <MobileView>
                        <SignatureCanvas ref={(ref) => { this.sigPad = ref }}
                                penColor='red'
                                canvasProps={{width: 325, height: 81, className: 'p-shadow-2' }} 
                                onEnd={() => this.setDirty()}
                            />
                        </MobileView>
                    </div>
                    <div className="p-mt-2">
                        <Button label="Clear"
                            onClick={this.clear}
                            style={{marginRight: "0.5rem"}}
                            icon="pi pi-times-circle"
                        />            
                        <Button label="Submit"
                            onClick={this.trim}
                            disabled={!this.state.dirty}
                            icon="pi pi-check"
                        />
                    </div> 
                </div>
                <div className="p-col-12 p-lg-6"><h3>Date issued:</h3> {this.state.currentDateTime}</div>
                <div className='p-col-12 p-lg-6'><h3>Issued By:</h3> {this.props.issuerName}</div>
            </div>            
        );
    }
}
      


export default KeyHandOutSigConfirm;