import React from 'react';

import Layout from '../components/Layout';
import GeneralDatatable from '../components/GeneralDatatable';
import { SupportService, PeopleService } from '../services/FetchServices';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Redirect } from 'react-router-dom';
import {Messages} from 'primereact/messages';
import CaseDetails from '../components/CaseDetails';
import TicketFilesComponent from '../components/TicketFilesComponent';

const statusItems = [
    {label: 'Open', value: 'Open'},
    {label: 'In Progress', value: 'In Progress'},
    {label: 'Closed', value: 'Closed'}
];

class SupportCases extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            selectedColumns:[
                {field:'tickeT_NO', header:'Case Number'},
                {field:'tickeT_STATUS', header:'Status'},
                {field:'creatE_DATE', header:'Date Created'},
                {field:'closE_DATE', header:'Date Closed'},
                {field:'tickeT_SUBJECT', header:'Short Description'},
                {field:'tickeT_DESC', header:'Long Description'},
                {field:'priority', header:'Priority'},
                {field:'category', header:'Category'}
            ],
            columnWidthTracker:"182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875",
            isLoading: true,
            data: [],
            detailsData: [],
            disabled: false,
            hasButton: true,
            caseDetailsButton: false,
            detailDialog: false,
            sid: "",
            guid: "",
            tickeT_NO: "",
            tickeT_STATUS: "Open",
            originalStatus: "",
            creatE_DATE: "",
            closE_DATE: "",
            lasT_UPDATED_BY: "",
            tickeT_SUBJECT: "",
            tickeT_DESC: "",
            priority: "Low",
            contacT_METHOD: "Email",
            tickeT_CONTACT: "",
            contacT_EMAIL: "",
            contacT_PHONE: "",
            category: "",
            filE1_PATH: "",
            filE2_PATH: "",
            filE3_PATH: "",
            filE4_PATH: "",
            filE5_PATH: "",
            updatedBy:"",
            newCase: false,
            dialogHeader: "New Support Case"
        }

        this.editRecord = this.editRecord.bind(this);
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleNewCase = this.handleNewCase.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
        this.updateCaseDetailData = this.updateCaseDetailData.bind(this);
        this.updateTicket = this.updateTicket.bind(this);
        this.handleTicketSave = this.handleTicketSave.bind(this);
    }

    async componentDidMount(props) {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let parsedTableData = JSON.parse(sessionStorage.getItem('supportCasesTableData'));
        const loggedInData = await PeopleService.fetchUsers( parsedUserData.uiU_M1ORGID, "", parsedUserData.uiU_ID )
        const loggedInEmail = loggedInData.user[0].uiU_EMAIL;
        const loggedInEmailArr = loggedInEmail.split("@");
        
        if(loggedInEmailArr[1] !== "mwspi.com") {
            this.setState({disabled: true})
        }; 

        await SupportService.getTickets( parsedUserData.uiU_M1ORGID, "").then(data => this.setState({ data: data.ticketlist, orgID: parsedUserData.uiU_M1ORGID, tickeT_CONTACT: parsedUserData.uiU_FN + " " + parsedUserData.uiU_LN}));
    
        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        this.setState({ 
            isLoading: false,
            updatedBy: parsedUserData.uiU_FN + " " + parsedUserData.uiU_LN
        })
    }

    handleLoading(){
        this.setState({ isLoading: true })
    }

    async editRecord(e) {
        // console.log("SUPPORT CLICK ROW",e)
        this.handleLoading();
        await SupportService.getTicketDetails( this.state.orgID, e.data.tickeT_NO)
        .then(data => this.setState({ 
            detailsData: data.ticketdetaillist, 
            detailDialog: true,
            isLoading: false,
            sid: e.data.sid,
            guid: e.data.guid,
            tickeT_NO: e.data.tickeT_NO,
            originalStatus: e.data.tickeT_STATUS,
            tickeT_STATUS: e.data.tickeT_STATUS,
            creatE_DATE: e.data.creatE_DATE,
            closE_DATE: e.data.closE_DATE,
            lasT_UPDATED_BY: e.data.lasT_UPDATED_BY,
            tickeT_SUBJECT: e.data.tickeT_SUBJECT,
            tickeT_DESC: e.data.tickeT_DESC,
            priority: e.data.priority,
            contacT_METHOD: e.data.contacT_METHOD,
            tickeT_CONTACT: e.data.tickeT_CONTACT,
            contacT_EMAIL: e.data.contacT_EMAIL,
            contacT_PHONE: e.data.contacT_PHONE,
            category: e.data.category,
            filE1_PATH: e.data.filE1_PATH,
            filE2_PATH: e.data.filE2_PATH,
            filE3_PATH: e.data.filE3_PATH,
            filE4_PATH: e.data.filE4_PATH,
            filE5_PATH: e.data.filE5_PATH,
            dialogHeader: "Case: " + e.data.tickeT_NO + ": " + e.data.tickeT_SUBJECT
        }));

        if(e.data.tickeT_STATUS!=="Closed") {
            this.setState({caseDetailsButton: true})
        }
    }

    async updateCaseDetailData(ticketNum) {
        await SupportService.getTicketDetails( this.state.orgID, ticketNum).then(data => this.setState({detailsData: data.ticketdetaillist}));
    }

    handleCancel() {
        this.setState({ 
            detailDialog: false,
            detailsData: [],
            newCase: false,
            pendingDelete: false,
            sid: "",
            guid: "",
            tickeT_NO: "",
            tickeT_STATUS: "Open",
            originalStatus: "",
            creatE_DATE: "",
            closE_DATE: "",
            lasT_UPDATED_BY: "",
            tickeT_SUBJECT: "",
            tickeT_DESC: "",
            priority: "Low",
            contacT_METHOD: "Email",
            tickeT_CONTACT: "",
            contacT_EMAIL: "",
            contacT_PHONE: "",
            category: "",
            filE1_PATH: "",
            filE2_PATH: "",
            filE3_PATH: "",
            filE4_PATH: "",
            filE5_PATH: "",
            dialogHeader: "New Support Case",
            caseDetailsButton: false
        })
    }

    handleDeleteCancel() {
        this.setState({pendingDelete: false})
    }

    showResults(data) {
        this.messages.clear();
        if(data.res) {
            this.messages.show({severity: 'success', summary: "Support Case Updated", life:1000});
        } else {
            this.messages.show({severity: 'error', summary: "Error", sticky: true});
        }
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    async handleTicketSave() {
        await SupportService.updateTicket( this.state.orgID, this.state.tickeT_NO, this.state.tickeT_STATUS, this.state.closE_DATE, this.state.lasT_UPDATED_BY, this.state.tickeT_SUBJECT, this.state.tickeT_DESC, this.state.priority, this.state.contacT_METHOD, this.state.tickeT_CONTACT, this.state.contacT_EMAIL, this.state.contacT_PHONE, this.state.category, this.state.filE1_PATH, this.state.filE2_PATH, this.state.filE3_PATH, this.state.filE4_PATH, this.state.filE5_PATH)
        .then(data => this.showResults(data));

        if(this.state.originalStatus !== this.state.tickeT_STATUS) {
            const detailComment = "Status Changed From: " + this.state.originalStatus + " to " + this.state.tickeT_STATUS;
            await SupportService.createTicketDetail( this.state.orgID, this.state.tickeT_NO, this.state.updatedBy, detailComment, "", "", "", "", "")
        }
        
        this.handleCancel();
        await SupportService.getTickets( this.state.orgID, "").then(data => this.setState({ data: data.ticketlist}));
    }

    async updateTicket(file1, file2, file3, file4, file5) {
        let file1path = this.state.filE1_PATH;
        let file2path = this.state.filE2_PATH;
        let file3path = this.state.filE3_PATH;
        let file4path = this.state.filE4_PATH;
        let file5path = this.state.filE5_PATH;

        if(file1!=="") {
            file1path = file1;
        } else if(file2!=="") {
            file2path = file2;
        } else if(file3!=="") {
            file3path = file3;
        } else if(file4!=="") {
            file4path = file4;
        } else if(file5!=="") {
            file5path = file5;
        }

        await SupportService.updateTicket( this.state.orgID, this.state.tickeT_NO, this.state.tickeT_STATUS, this.state.closE_DATE, this.state.lasT_UPDATED_BY, this.state.tickeT_SUBJECT, this.state.tickeT_DESC, this.state.priority, this.state.contacT_METHOD, this.state.tickeT_CONTACT, this.state.contacT_EMAIL, this.state.contacT_PHONE, this.state.category, file1path, file2path, file3path, file4path, file5path)
    }

    handleNewCase() {
        this.setState({newCase: true})
    }

    handleFieldChange(e) {
        if(e.target.id==='category') {
            this.setState({ category: e.target.value })
        } else if (e.target.id==='contacT_PHONE') {
            this.setState({ contacT_PHONE: e.target.value })
        } else if (e.target.id==='contacT_EMAIL') {
            this.setState({ contacT_EMAIL: e.target.value })
        } else if (e.target.id==='tickeT_CONTACT') {
            this.setState({ tickeT_CONTACT: e.target.value })
        } else if (e.target.id==='contacT_METHOD') {
            this.setState({ contacT_METHOD: e.target.value })
        } else if (e.target.id==='priority') {
            this.setState({ priority: e.target.value })
        } else if (e.target.id==='tickeT_DESC') {
            this.setState({ tickeT_DESC: e.target.value })
        } else if (e.target.id==='tickeT_SUBJECT') {
            this.setState({ tickeT_SUBJECT: e.target.value })
        } else if (e.target.id==='closE_DATE') {
            this.setState({ closE_DATE: e.target.value })
        } else if (e.target.id==='lasT_UPDATED_BY') {
            this.setState({ lasT_UPDATED_BY: e.target.value })
        } else if (e.target.id==='newUpdatedBy') {
            this.setState({ newUpdatedBy: e.target.value })
        } else if (e.target.id==='tickeT_NO') {
            this.setState({ tickeT_NO: e.target.value })
        } else if (e.target.id==='tickeT_STATUS') {
            this.setState({ tickeT_STATUS: e.target.value })
        }
    }

    renderButtons() {
        return(
            <div>
                <Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleTicketSave} />
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCancel} />
            </div>
        )
    }

    renderFooter() {
        return(
            <div>
                <Button type='button' label='Cancel' icon='pi pi-times' onClick={this.handleDeleteCancel} />
            </div>
            );
    }

    render(){    
        const buttonFooter=this.renderButtons();
        const deleteFooter=this.renderFooter();
        if(this.state.newCase) {
            return(
                <Redirect to='/newsupportcase' />
            )
        } else if(this.state.data.length===0 && this.state.newCase!==true && !this.state.isLoading) {
            return(
                <div>
                    <Layout><div style={{marginBottom:'40px'}}><Button label='New Support Case' icon='pi pi-plus' onClick={this.handleNewCase}/></div></Layout>
                </div>
            );  
        } else {
            return(
                <div>
                    <Layout>
                        <Messages ref={(el) => this.messages = el} />
                        <GeneralDatatable
                            columnArray = {[
                                {field:'tickeT_NO', header:'Case Number'},
                                {field:'tickeT_STATUS', header:'Status'},
                                {field:'creatE_DATE', header:'Date Created'},
                                {field:'closE_DATE', header:'Date Closed'},
                                {field:'tickeT_SUBJECT', header:'Short Description'},
                                {field:'tickeT_DESC', header:'Long Description'},
                                {field:'priority', header:'Priority'},
                                {field:'category', header:'Category'}
                            ]}

                            csvHeader = {[
                                {key:'tickeT_NO', label:'Case Number'},
                                {key:'tickeT_STATUS', label:'Status'},
                                {key:'creatE_DATE', label:'Date Created'},
                                {key:'closE_DATE', label:'Date Closed'},
                                {key:'tickeT_SUBJECT', label:'Short Description'},
                                {key:'tickeT_DESC', label:'Long Description'},
                                {key:'priority', label:'Priority'},
                                {key:'category', label:'Category'}
                            ]}
                            
                            defaultOrder={["tickeT_NO", "tickeT_STATUS", "creatE_DATE", "closE_DATE", "tickeT_SUBJECT", "tickeT_DESC", "priority", "category"]}
                            tableRecords = {this.state.data}
                            pageNumber={37}
                            tableName={'Support Cases'}
                            exportFileName={'SupportCases.csv'}
                            tableData={'supportCasesTableData'}
                            filterData={'supportCasesFilterData'}
                            columnWidthTracker={this.state.columnWidthTracker}
                            isLoading={this.state.isLoading}
                            selectedColumns={this.state.selectedColumns}
                            handleRowClick={this.editRecord}
                            handleWidth={this.handleWidth}
                            handleSelectedColumns={this.handleSelectedColumns}
                            hasButton={this.state.hasButton}
                            buttonLabel={'New Support Case'}
                            onButtonClick={this.handleNewCase}
                        />
                    </Layout>
                    <Dialog header={this.state.dialogHeader}  visible={this.state.detailDialog} footer={buttonFooter} onHide={this.handleCancel} baseZIndex={1300} maximized blockScroll focusOnShow={false} closable={false}>
                        <div style={{width:'99vw', maxWidth:'100%'}}>
                            <div className="p-grid p-justify-center">

                                <div className="p-col-12 p-lg-8">
                                    <div className='p-grid p-justify-start'>
                                        <div className="p-col-12 p-lg-2" style={{textAlign:'left'}}>
                                                <strong>{"Status: "}</strong>
                                        </div>
                                        <div className='p-col-12 p-lg-12' >
                                            <Dropdown placeholder={"Select Status"} style={{textAlign:'left', width:'100%'}} disabled={this.state.disabled} options={statusItems}
                                            value={this.state.tickeT_STATUS} onChange={(e) => this.handleFieldChange(e)} type='text' id={"tickeT_STATUS"} />
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-8">
                                    <div className='p-grid p-justify-start'>
                                        <div className="p-col-12" style={{textAlign:'left'}}>
                                                <strong>{"Category: "}</strong>
                                                <span>{this.state.category}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-8">
                                    <div className='p-grid p-justify-start'>
                                        <div className="p-col-12" style={{textAlign:'left'}}>
                                                <strong>{"Priority: "}</strong>
                                                <span>{this.state.priority}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-8">
                                    <div className='p-grid p-justify-start'>
                                        <div className="p-col-12" style={{textAlign:'left'}}>
                                                <strong>{"Case Number: "}</strong>
                                                <span>{this.state.tickeT_NO}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-8">
                                    <div className='p-grid p-justify-start'>
                                        <div className="p-col-12" style={{textAlign:'left'}}>
                                                <strong>{"Contact Email: "}</strong>
                                                <span>{this.state.contacT_EMAIL}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-8">
                                    <div className='p-grid p-justify-start'>
                                        <div className="p-col-12" style={{textAlign:'left'}}>
                                                <strong>{"Short Description: "}</strong>
                                                <span>{this.state.tickeT_SUBJECT}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-8">
                                    <div className='p-grid p-justify-start'>
                                        <div className="p-col-12 p-lg-2" style={{textAlign:'left'}}>
                                                <strong>{"Long Description: "}</strong>
                                        </div>
                                        <div className='p-col-12 p-lg-12' >
                                            <InputTextarea autoResize rows={5} placeholder={"[Enter Long Description Here]"} disabled={true} style={{width:'100%'}} 
                                            value={this.state.tickeT_DESC} onChange={(e) => this.handleFieldChange(e)} type='text' id={"tickeT_DESC"} />
                                        </div>
                                    </div>
                                </div>
                                    
                                    {/* commented out until we are ready to do images
                                    <TicketFilesComponent 
                                        blobFilePath1={`${this.state.tickeT_NO}-file1`}
                                        blobFilePath2={`${this.state.tickeT_NO}-file2`}
                                        blobFilePath3={`${this.state.tickeT_NO}-file3`}
                                        blobFilePath4={`${this.state.tickeT_NO}-file4`}
                                        blobFilePath5={`${this.state.tickeT_NO}-file5`}
                                        containerName={"-support-case"}
                                        newCase={false}
                                        newCaseDetail={false}
                                        updateTicket={this.updateTicket}
                                    /> */}

                                <div className="p-col-12">
                                    <CaseDetails
                                        detailsData={this.state.detailsData}
                                        orgID={this.state.orgID}
                                        ticketNum={this.state.tickeT_NO}
                                        updatedBy={this.state.updatedBy}
                                        caseDesc={this.state.tickeT_DESC}
                                        updateCaseDetailData={this.updateCaseDetailData}
                                        hasButton={this.state.caseDetailsButton}
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header={"Delete " + this.state.groupName}  visible={this.state.pendingDelete} footer={deleteFooter} onHide={this.handleDeleteCancel} baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                        <h4>Are you sure you would like to delete '{this.state.groupName}'?</h4>
                    </Dialog>
                </div>
            );
        }
    }
}

export default SupportCases;