import React from 'react';

import GeneralDatatable from './GeneralDatatable';
import {Messages} from 'primereact/messages';
import {KeyService} from '../services/FetchServices';
import { serverAddr } from '../services/serverAddr';
import { BlobService } from '../services/BlobServices';

class KeyHistoryComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            keyHistData: [],
            selectedColumns: [
                {field:'fullname', header:'Associate'},
                {field:'keymark', header:'Key Marking'},
                {field:'eventtext', header:'Event'},
                {field:'notes', header:'Notes'},
                {field:'evenT_DATE_TIME', header:'Date'}
            ],
            columnWidthTracker: "292.6,292.6,292.6,292.6,292.6",
            defaultOrder: ["fullname", "keymark", "eventtext", "notes", "evenT_DATE_TIME"],
            isLoading: true
        };
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.editRecord = this.editRecord.bind(this);
    }


    async componentDidMount() {
        let parsedTableData = JSON.parse(sessionStorage.getItem('KeyHistoryTableData'));
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }
        let keyHistData = [];
        let tempArray = [];
        await KeyService.FetchKeyEvents(parsedUserData.uiU_M1ORGID, this.props.keyID, this.props.userID).then(data => keyHistData = data.keyeventlist)

        for(let i=0; i<keyHistData.length; i++) {
            let dateTime = keyHistData[i].evenT_DATE_TIME.split('-');
            let timeVar = dateTime[2].split('T');
            let month;
            if(dateTime[1][0]==='0') {
                month = (dateTime[1].split(''))
                month = month[1]
            } else {
                month = dateTime[1]
            }
            let dateTimeStr = month + '/' + timeVar[0] + '/' + dateTime[0] + ' ' + timeVar[1];
            tempArray.push({
                orG_ID: keyHistData[i].orG_ID,
                keY_ID: keyHistData[i].keY_ID,
                useR_ID: keyHistData[i].useR_ID,
                fullname: keyHistData[i].fullname,
                keymark: keyHistData[i].keymark,
                eventtext: keyHistData[i].eventtext,
                keY_EVENT_ID: keyHistData[i].keY_EVENT_ID,
                notes: keyHistData[i].notes,
                filE1: keyHistData[i].filE1,
                filE2: keyHistData[i].filE2,
                evenT_DATE_TIME: dateTimeStr,
            })
        }

        this.setState({ isLoading: false, keyHistData: tempArray })
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    async editRecord(e) {
        if(e.data.keY_EVENT_ID===1) {
            const blobName = `${e.data.filE1}`;
            const containerName = serverAddr.blobContainer + "-keyhandout-files";
            const downloadedFile = await BlobService.downloadBlob(containerName, blobName);
            window.open(`${downloadedFile}`, "_blank");
        } else if(e.data.keY_EVENT_ID===2) {
            const blobName = `${e.data.filE1}`;
            const containerName = serverAddr.blobContainer + "-keyhandin-files";
            const downloadedFile = await BlobService.downloadBlob(containerName, blobName);
            window.open(`${downloadedFile}`, "_blank");
        }
    }

    render() {
        return(
            <div>
                <Messages ref={(el) => this.messages = el} />
                {(this.state.keyHistData.length===0 && !this.state.isLoading) ? (<h3>This key currently has no history.</h3>) : (
                    <div>
                        <GeneralDatatable 
                            columnArray = {[
                                {field:'fullname', header:'Associate'},
                                {field:'keymark', header:'Key Marking'},
                                {field:'eventtext', header:'Event'},
                                {field:'notes', header:'Notes'},
                                {field:'evenT_DATE_TIME', header:'Date'}
                            ]}

                            csvHeaders = {[
                                {key:'fullname', label:'Associate'},
                                {key:'keymark', label:'Key Marking'},
                                {key:'eventtext', label:'Event'},
                                {key:'notes', label:'Notes'},
                                {key:'evenT_DATE_TIME', label:'Date'}
                            ]}
                            
                            defaultOrder={["fullname", "keymark", "eventtext", "notes", "evenT_DATE_TIME"]}

                            tableRecords = {this.state.keyHistData}
                            pageNumber={35}
                            tableName={'Key History'}
                            exportFileName={'KeyHistory.csv'}
                            tableData={'KeyHistoryTableData'}
                            filterData={'KeyHistoryFilterData'}
                            columnWidthTracker={this.state.columnWidthTracker}
                            isLoading={this.state.isLoading}
                            selectedColumns={this.state.selectedColumns}
                            handleRowClick={this.editRecord}
                            handleWidth={this.handleWidth}
                            handleSelectedColumns={this.handleSelectedColumns}   
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default KeyHistoryComponent;