import React from 'react';

import KeysComponent from '../components/KeysComponent';
import { Redirect } from 'react-router-dom';

//Page 3 for API, page 2 for internal routing
class Keys extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            redirectToLazyKeys: false
        }

    }

    componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        if(parsedUserData.uiU_M1ORGID ==="10647" || parsedUserData.uiU_M1ORGID ==="7680" ) {
            this.setState({redirectToLazyKeys: true})
        }
    }

    render() {
        if(this.state.redirectToLazyKeys) {
            return(
                <Redirect to='/lkeys' />
            )
        } else {
            return(
                <div>
                    <KeysComponent employeeID={0} recordID={0} pageID={3} />
                </div>
            );
        }
    }
}

export default Keys;