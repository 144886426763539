import React from 'react';

import { serverAddr } from '../services/serverAddr';
import GeneralDatatable from '../components/GeneralDatatable';
import { BlobService } from '../services/BlobServices';
import KeyDatatable from '../components/KeyDatatable';
import { CoreService, KeyService } from '../services/FetchServices';
import { DoorService } from '../services/FetchServices';
import DoorCoreDatatable from '../components/DoorCoreDatatable';
import DoorHistDatatable from '../components/DoorHistDatatable';
import Layout from '../components/Layout';
import placeholderImg from '../images/placeholderImg.png';
import TextBox from '../components/inputComponents/textbox';
import InputSelect from '../components/inputComponents/InputSelect';
import UnassignedCoresTable from '../components/UnassignedCoresTable';

import {Button} from 'primereact/button';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Messages} from 'primereact/messages';
import {Dialog} from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { TabView,TabPanel } from 'primereact/tabview';
import { Card } from 'primereact/card';
import { BrowserView, MobileView } from 'react-device-detect';
import Webcam from "react-webcam";

let doorPicArray=[];
let isPlaceholderArray=[];

const cardReaderOptions = [
    {label: 'Prox - Mullion', value: '1'},
    {label: 'Prox - Mullion - Bluetooth', value: '7'},
    {label: 'Prox - Single Gang', value: '2'},
    {label: 'Prox - Single Gang - Bluetooth', value: '8'},
    {label: 'Prox with PIN', value: '3'},
    {label: 'Prox with PIN - Bluetooth', value: '9'},
    {label: 'Long Range', value: '4'},
    {label: 'Long Range - Bluetooth', value: '10'},
    {label: 'Wireless', value: '5'},
    {label: 'Mag Swipe', value: '6'}
];

const yesNoOptions = [
    {label: 'Yes', value: '1'},
    {label: 'No', value: '2'}
];

const lockPowerOptions = [
    {label: '12VDC', value: '1'},
    {label: '12VAC', value: '2'},
    {label: '24VDC', value: '3'},
    {label: '24VAC', value: '4'},
    {label: '8AA', value: '5'}
];

const rexOptions = [
    {label: 'Motion', value: '1'},
    {label: 'Push Button', value: '2'},
    {label: 'Card Reader', value: '3'},
    {label: 'Integrated', value: '4'}
];

const remoteReleaseOptions = [
    {label: 'Yes - Location in Notes', value: '1'},
    {label: 'No', value: '2'},
];

const powerTransferOptions = [
    {label: 'Hinge', value: '1'},
    {label: 'Door Cord', value: '2'},
    {label: 'EPT', value: '3'}
];
 
const doorTypeOptions = [
    {label: 'Single - Interior', value: 'SI'},
    {label: 'Single - Exterior', value: 'SE'},
    {label: 'Pair - Interior', value: 'PI'},
    {label: 'Pair - Exterior', value: 'PE'},
    {label: 'Gate - Interior', value: 'GI'},
    {label: 'Gate - Exterior', value: 'GE'},
];

const handingOptions = [
    {label: 'LH', value: 'LH'},
    {label: 'RH', value: 'RH'},
    {label: 'LHR', value: 'LHR'},
    {label: 'RHR', value: 'RHR'},
    {label: 'LHLA', value: 'LHLA'},
    {label: 'RHRA', value: 'RHRA'},
    {label: 'LHRLA', value: 'LHRLA'},
    {label: 'RHRRA', value: 'RHRRA'},
];

const hardwareOptions = [
    {value: 'CKKIK', label: 'Cyl. Knob KIK'},
    {value: 'CLKIL', label: 'Cyl. Lever KIK'},
    {value: 'CKIC', label: 'Cyl. Knob I/C'},
    {value: 'CLIC', label: 'Cyl. Lever I/C'},
    {value: 'M', label: 'Mort'},
    {value: 'MK', label: 'Mort Knob'},
    {value: 'ML', label: 'Mort lever'},
    {value: 'MKIC', label: 'Mort Knob I/C'},
    {value: 'MLIC', label: 'Mort lever I/C'},
    {value: 'RIM', label: 'Rim'},
    {value: 'RIMIC', label: 'Rim I/C'},
    {value: 'DSDB', label: 'Double Sided Deadbolt'},
    {value: 'DB', label: 'Deadbolt'},
    {value: 'DBIC', label: 'Deadbolt I/C'},
    {value: 'ED', label: 'Exit Device'},
    {value: 'EDWR', label: 'Exit w/ Rim'},
    {value: 'EWRIC', label: 'Exit w/ Rim I/C'},
    {value: 'EWM', label: 'Exit w/ Mort'},
    {value: 'EWMIC', label: 'Exit w/ Mort I/C'}

];

const functionOptions = [
    {value: 'PA', label: 'Passage'},
    {value: 'OE', label: 'Office / Entrance'},
    {value: 'ST', label: 'Storeroom'},
    {value: 'CR', label: 'Classroom'},
    {value: 'CRI', label: 'Classroom / Intruder'},
    {value: 'PR', label: 'Privacy'},
    {value: 'ED', label: 'Electrified Device'}
]

const closerMFGOptions = [
    {label: 'Not Installed', value: '0'},
    {label: 'Arrow', value: '1'},
    {label: 'Corbin Russwin', value: '2'},
    {label: 'LCN', value: '3'},
    {label: 'Norton', value: '4'},
    {label: 'Sargent', value: '5'},
    {label: 'Schlage', value: '6'},
    {label: 'Yale', value: '7'},
    {label: 'Other (See Notes)', value: '10'}
];

const thicknessOptions = [
    {label: '1-3/4"', value: '234'},
    {label: '1-3/8"', value: '238'},
    {label: 'Other', value: 'OTHER'}
];

const backSetOptions = [
    {label: '2-3/4"', value: '134'},
    {label: '2-3/8"', value: '138'},
    {label: 'Other', value: 'OTHER'}
];

const doorMatOptions = [
    {label: 'Wood Door', value: 'WD'},
    {label: 'Wood Split Door', value: 'WSD'},
    {label: 'Wood Frame Glass Door', value: 'WFGD'},
    {label: 'Hollow Metal Door', value: 'HMD'},
    {label: 'Aluminum Frame Glass Door', value: 'AFGD'},
    {label: 'Custom Door', value: 'CD'}
];

const frameMatOptions = [
    {label: 'Wood', value: 'W'},
    {label: 'Hollow Metal Slushed', value: 'HMS'},
    {label: 'Hollow Metal', value: 'HM'},
    {label: 'Aluminum SF', value: 'ASF'}
];

const containerName = serverAddr.blobContainer + "-door-pic";

const videoConstraints = {
    facingMode: { exact: "environment" }
}

class DoorsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            selectedColumns:[
                {field:'dooR_NUMBER', header:'Number'},
                {field:'area', header:'Area'},
                {field:'description', header:'Description'},
                {field:'type', header:'Type'},
                {field:'locatioN_NAME', header:'Building'},
                {field:'corE_LIST', header:'Cores Assigned'}
            ],
            columnWidthTracker:"243.9,243.9,243.9,243.9,243.9,243.9",
            data:[],
            keyData: [],
            doorPicSrc: [],
            displayRowD: false,
            scaleSlider: 12,
            rotateImg: 0,
            newDoor: false,
            webcamOn: false,
            coreData: [],
            unassignedCores: [],
            areaList:'',
            editAreaOr: '',
            isPlaceholder: [],
            confirmDelete: false,
            orgID: '',
            editDoorID: '',
            editDoorNum: '',
            editArea: '',
            editType: '',
            editLocID: '',
            editLocName: '',
            editNotes: '',
            editHanding: '',
            editHardware: '',
            editFunction: '',
            editCloserPN: '',
            editThickness: '',
            editBackSet: '',
            editDoorMaterial: '',
            editCodePeg: 0,
            editElectricLev1: 0,
            editElectricLev2: 0,
            editFrameMaterial: '',
            editDoorType: '',
            editCardReader: '',
            editStrike: '',
            editLockPower: '',
            editSmartPack: '',
            editDoorContact: '',
            editHorn: '',
            editREX: '',
            editRemoteRelease: '',
            editPowerTransfer: '',
            editEAC10: '',
            editCloserMfg: '',
            editReaderIn: '',
            editReaderOut: '',
            editPicDesc1: '',
            editPicDesc2: '',
            editPicDesc3: '',
            editPicDesc4: '',
            editPicDesc5: '',
            editPicDesc6: '',
            editPicDesc7: '',
            editPicDesc8: '',
            secRole:'0',
            editDoorDesc: '',
            // isLoading: false,
            componentIsLoading: true
        };

        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.showResults = this.showResults.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleBlobDelete = this.handleBlobDelete.bind(this);
        this.myUploader = this.myUploader.bind(this);
        this.handleStoreDoor = this.handleStoreDoor.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.editImage = this.editImage.bind(this);
        this.closeEditImg = this.closeEditImg.bind(this);
        this.setWebcamRef = this.setWebcamRef.bind(this);
        this.capture = this.capture.bind(this);
        this.cancelWebcam = this.cancelWebcam.bind(this);
        this.handleWebcam = this.handleWebcam.bind(this);
        this.unassignCore = this.unassignCore.bind(this);
        this.assignCore = this.assignCore.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.saveAssignCore = this.saveAssignCore.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
        this.handlePicLoading = this.handlePicLoading.bind(this);
        this.handleBlobDownload = this.handleBlobDownload.bind(this);
        this.handleNewDoor = this.handleNewDoor.bind(this);
        this.addCore = this.addCore.bind(this);
        this.closeConfirmDelete = this.closeConfirmDelete.bind(this);
        this.handleFetchDoors = this.handleFetchDoors.bind(this);
        this.cancelAddCore = this.cancelAddCore.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        this.setState({  orgID: (parsedUserData.uiU_M1ORGID.toLowerCase()), secRole: parsedUserData.secString[28] })

        if (parsedUserData.secString[28]!=='3') {
            this.setState({disabled: true})
        } else {
            this.setState({disabled: false})
        }
        let parsedTableData = JSON.parse(sessionStorage.getItem('doorsTableData'));

        await this.handleFetchDoors();

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        } 

        this.setState({componentIsLoading: false})
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    showResults(data) {
        this.messages.clear();
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
        } else {
            this.messages.show({severity: 'error', summary: data.message, sticky: true});
        }
    }

    renderDeleteFooter() {
        if(this.state.coreData.length>0) {
            return(
                <div>
                    <Button label='Cancel' icon='pi pi-times' onClick={this.cancelDelete} />
                </div>
            );
        } else {
            return(
                <div>
                    <Button label='Delete' icon='pi pi-trash' onClick={this.handleDelete} />
                    <Button label='Cancel' icon='pi pi-times' onClick={this.cancelDelete} />
                </div>
            );
        }
    }

    async handleFetchDoors() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await DoorService.fetchDoors( parsedUserData.uiU_M1ORGID, this.props.doorID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "",
            this.props.coreMarkID, this.props.buildingID, this.props.keymark, this.props.keyway, this.props.system ).then(data => this.setState({ data: data.doorslist })
        );
    }

    async handleStoreDoor() {
        if(this.state.editDoorNum==="" ||this.state.editDoorNum===null || this.state.editDoorNum===undefined) {
            this.setState({errorMsg: true })
        } else {
            await DoorService.storeDoor( 0, null, this.state.orgID, this.state.editDoorID, this.state.editDoorNum, this.state.editArea, this.state.editDoorDesc, this.state.editType, 
                this.state.editLocID, this.state.editLocName, this.state.editNotes, false, this.state.editHanding, this.state.editHardware, this.state.editFunction, 
                this.state.editCloserPN, this.state.editThickness, this.state.editBackSet, this.state.editDoorMaterial, this.state.editCodePeg, this.state.editElectricLev1, 
                this.state.editElectricLev2, this.state.editFrameMaterial, this.state.editDoorType, this.state.editCardReader, this.state.editStrike, this.state.editLockPower,
                this.state.editSmartPack, this.state.editDoorContact, this.state.editHorn, this.state.editREX, this.state.editRemoteRelease, this.state.editPowerTransfer,
                this.state.editEAC10, this.state.editCloserMfg, this.state.editReaderIn, this.state.editReaderOut, '', this.state.editPicDesc1, this.state.editPicDesc2, 
                this.state.editPicDesc3, this.state.editPicDesc4, this.state.editPicDesc5, this.state.editPicDesc6, this.state.editPicDesc7, this.state.editPicDesc8 )
                .then(data => this.showResults(data));
            await this.handleFetchDoors();
            this.handleClose();
            this.setState({
                errorMsg: false 
            });
        }
    }

    async handleBlobDownload(doorID) {
        doorPicArray=[];
        isPlaceholderArray=[];
        for(let i=0; i<8; i++) {
            const blobName = `${this.state.orgID}${doorID}-${i}`;
            const downloadedPic = await BlobService.downloadBlob(containerName, blobName);
            if(downloadedPic===false) {
                doorPicArray.push(placeholderImg)
                isPlaceholderArray.push(true)
            } else {
                doorPicArray.push(downloadedPic)
                isPlaceholderArray.push(false)
            }
        }
        this.setState({ doorPicSrc: doorPicArray, isPlaceholder: isPlaceholderArray})
    }

    async handleDelete() {
        if(this.state.pendingDelete) {
            await DoorService.deleteDoor(this.state.editDoorID, this.state.orgID).then(data => this.showResults(data));
            await this.handleFetchDoors();
            this.setState({ pendingDelete: false });
            this.handleClose();
        } else {
            this.setState({ pendingDelete: true })
        }
    }

    async handleBlobDelete() {
        if(this.state.confirmDelete) {
            const blobName = `${this.state.orgID}${this.state.editDoorID}-${this.state.editImgIndex}`
            await this.handlePicLoading();
            await BlobService.deleteBlob(containerName, blobName)
            await this.handleBlobDownload(this.state.editDoorID)
            this.setState({ 
                editImgDialog: false,
                isPicLoading: false,
                confirmDelete: false
            })
        } else {
            this.setState({confirmDelete: true})
        }
    }

    cancelDelete() {
        this.setState({ pendingDelete: false })
    }

    async handleLoading() {
        this.setState({ componentIsLoading:true })
    }

    async editRecord(e) {
        if(this.state.secRole!=='1') {
            let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
            await this.handleLoading();
            await this.handleBlobDownload(e.data.dooR_ID);
            await KeyService.fetchIssuedKeys(this.state.orgID, parsedUserData.uiU_ID, 0, e.data.dooR_ID).then(data => this.setState({keyData: data.keylist}))
            await CoreService.fetchCores(0, this.state.orgID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, e.data.dooR_ID).then(data => this.setState({ coreData: data.corelist }))
            await DoorService.fetchDoorHist(parsedUserData.uiU_M1ORGID, e.data.dooR_ID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "" ).then(data => this.setState({ doorHistData: data.doorslist }));
            const areaList = await DoorService.fetchAreas(this.state.orgID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, e.data.locatioN_ID)
            doorPicArray= [];

            const areaListOption = areaList.map((option, i) => {
                return {key:option.areA_NAME, label: option.areA_NAME, value: option.areA_NAME }
            });
        
            this.setState({ 
                areaListOptions: areaListOption,

                displayRowD: true,
                editDoorID: e.data.dooR_ID,
                editDoorNum: e.data.dooR_NUMBER,
                editDoorDesc: e.data.description,
                editArea: e.data.area,
                editType: e.data.type,
                editCloserPN: e.data.closer,
                editNotes: e.data.notes,

                editDoorType: e.data.doortype,
                editHanding: e.data.handing,
                editHardware: e.data.hardware,
                editFunction: e.data.function,
                editCloserMfg: e.data.closeR_MFG,
                editThickness: e.data.thickness,
                editBackSet: e.data.setback,
                editDoorMaterial: e.data.woodmetal,
                editFrameMaterial: e.data.frame,

                editCardReader: e.data.eaC1,
                editStrike: e.data.eaC2,
                editLockPower: e.data.eaC3,
                editSmartPack: e.data.eaC4,
                editDoorContact: e.data.eaC5,
                editHorn: e.data.eaC6,
                editREX: e.data.eaC7,
                editRemoteRelease: e.data.eaC8,
                editPowerTransfer: e.data.eaC9,

                editLocID: e.data.locatioN_ID,
                editLocName: e.data.locatioN_NAME,
                editCodePeg: e.data.codE_PEG_ID,
                editElectricLev1: e.data.eleV1_ID,
                editElectricLev2: e.data.eleV2_ID,
                editEAC10: e.data.eaC10,
                editReaderIn: e.data.readeR_IN,
                editReaderOut: e.data.readeR_OUT,

                editPicDesc1: e.data.piC1_DESC,
                editPicDesc2: e.data.piC2_DESC,
                editPicDesc3: e.data.piC3_DESC,
                editPicDesc4: e.data.piC4_DESC,
                editPicDesc5: e.data.piC5_DESC,
                editPicDesc6: e.data.piC6_DESC,
                editPicDesc7: e.data.piC7_DESC,
                editPicDesc8: e.data.piC8_DESC,

                componentIsLoading:false
            })
        }
    }    

    handleClose() {
        this.setState({ 
            displayRowD: false,
            globalDetailsFilter: null,
            newDoor: false,

            editDoorID:'',
            editDoorNum: '',
            editDoorDesc: '',
            editArea: '',
            editType: '',
            editCloserPN: '',
            editNotes: '',
            editAreaOr: '',

            editDoorType: '',
            editHanding: '',
            editHardware: '',
            editFunction: '',
            editCloserMfg: '',
            editThickness: '',
            editBackSet: '',
            editDoorMaterial: '',
            editFrameMaterial: '',


            editCardReader: '',
            editStrike: '',
            editLockPower: '',
            editSmartPack: '',
            editDoorContact: '',
            editHorn: '',
            editREX: '',
            editRemoteRelease: '',
            editPowerTransfer: '',

            editLocID: 0,
            editLocName: '',
            editCodePeg: 0,
            editElectricLev1: 0,
            editElectricLev2: 0,
            editEAC10: '',
            editReaderIn: '',
            editReaderOut: '',
            activeIndex: 0,
            coreData:[],
            doorHistData:'',
            areaList: '',
            
            editPicDesc1: "",
            editPicDesc2: "",
            editPicDesc3: "",
            editPicDesc4: "",
            editPicDesc5: "",
            editPicDesc6: "",
            editPicDesc7: "",
            editPicDesc8: "",
            keyData: ''
        })
    }

    handleNewDoor() {
        doorPicArray=[];
        let placeArray=[];
        let tempVar = this.props.buildingID;

        for(let i=0; i<8; i++) {
            doorPicArray.push(placeholderImg);
            placeArray.push(true)
        }

        this.setState({ 
            newDoor: true,
            doorPicSrc: doorPicArray,
            editDoorID: -1,
            isPlaceholder: placeArray,
            editLocID: tempVar
        })
    }

    editImage(index) {
        switch(index) {
            case 0:
                this.setState({ 
                    editImgIndex: 0,
                    editImgDialog: true    
                }) 
                break;
            case 1:
                this.setState({ 
                    editImgIndex: 1,
                    editImgDialog: true    
                })
                break;
            case 2:
                this.setState({ 
                    editImgIndex: 2,
                    editImgDialog: true    
                })
                break;
            case 3:
                this.setState({ 
                    editImgIndex: 3,
                    editImgDialog: true    
                })
                break;
            case 4:
                this.setState({ 
                    editImgIndex: 4,
                    editImgDialog: true    
                })
                break;
            case 5:
                this.setState({ 
                    editImgIndex: 5,
                    editImgDialog: true    
                })
                break;
            case 6:
                this.setState({ 
                    editImgIndex: 6,
                    editImgDialog: true    
                })
                break;
            case 7:
                this.setState({ 
                    editImgIndex: 7,
                    editImgDialog: true    
                })
                break;
            default:
                console.log('default error, Doors component line 604',)
                break;
        }
    }

    closeEditImg() {
        this.setState({
            editImgDialog: false,
            editImgIndex: null,
            webcamOn: false
        })
    }

    async myUploader(event) {
        const imgState = event.files[0].objectURL;
        const blobName = `${this.state.orgID}${this.state.editDoorID}-${this.state.editImgIndex}`
        await BlobService.uploadBlob(containerName, blobName, imgState);
        await this.handleBlobDownload(this.state.editDoorID);
    }

    setWebcamRef = (webcamRef) => {this.webcamRef = webcamRef};

    handleWebcam() {
        this.setState({ webcamOn: true })
    }

    async capture() {
        const captureImg = this.webcamRef.getScreenshot();
        const blobName = `${this.state.orgID}${this.state.editDoorID}-${this.state.editImgIndex}`
        await this.handlePicLoading();
        await BlobService.uploadBlob(containerName, blobName, captureImg);
        await this.handleBlobDownload(this.state.editDoorID);
        this.setState({ 
            webcamOn: false,
            isPicLoading: false
        })
    }

    handlePicLoading() {
        this.setState({ isPicLoading:true })
    }

    cancelWebcam(){
        this.setState({ webcamOn: false })
    }

    async unassignCore(e) {
        if(this.state.secRole==='3') {
            if(this.state.coreEdit) {
                let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));

                await CoreService.removeCore( this.state.orgID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, this.state.editDoorID, this.state.editCoreID )  
                await CoreService.fetchCores(0, this.state.orgID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, this.state.editDoorID).then(data => this.setState({ coreData: data.corelist, coreEdit: false, editCoreID: '', editCoreMark: '' }))

            } else {
                this.setState({ 
                    coreEdit: true,
                    editCoreID: e.data.corE_ID,
                    editCoreMark: e.data.fulL_CODE,
                })
            }
        }
    }
    
    async assignCore() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        const unassignedCoreList = await CoreService.fetchCores(0, this.state.orgID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, 0);
        this.setState({ addCoreDialog: true, unassignedCores: unassignedCoreList.corelist })
    }

    async saveAssignCore() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await CoreService.addCore(this.state.orgID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, this.state.editDoorID, this.state.addCoreSelection)
        await CoreService.fetchCores(0, this.state.orgID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, this.state.editDoorID).then(data => this.setState({ coreData: data.corelist, addCoreSelection: ''}))
        this.handleCancel();
    }

    addCore(e) {
        if(e!==undefined) {
            this.setState({ 
                assignCoreDialog: true,
                addCoreSelection: e.data.corE_ID,
                coreName: e.data.fulL_CODE
            })
        }
    }

    cancelAddCore(e) {
        this.setState({ 
            assignCoreDialog: false,
            addCoreSelection: '',
            coreName: ''
        })
    }
    handleCancel() {
        this.setState({ 
            coreEdit: false,
            addCoreDialog: false,
            editCoreID: '',
            editCoreMark: '',
            unassignedCores:'',
            addCoreSelection: '',
            assignCoreDialog: false,
            coreName: ''
        })
    }

    closeConfirmDelete() {
        this.setState({confirmDelete: false})
    }

    renderButtons() {
        return(                                                
            <div>
                {this.state.errorMsg ? (<span style={{color:'red', marginRight:'15px'}}>Door Number Cannot Be Empty</span>) : (null)}
                {(this.state.newDoor || this.state.secRole!=='3') ? (null) : (<Button type='button' label='Delete' icon='pi pi-trash' onClick={this.handleDelete} />)}
                {this.state.secRole==='3' ? (<Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleStoreDoor} />) : (null)}
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} />
            </div>
        )
    }

    renderCoreFooter() {
        if(this.state.assignCoreDialog) {
            return (
                <div className='p-grid p-justify-between'>
                    <div className='p-col-6'>
                        {this.state.secRole==='3' ? (<Button style={{ float:"left" }} type='button' label='Assign' icon='pi pi-check' onClick={this.saveAssignCore} />) : (null)}
                    </div>
                    <div className='p-col-6'>
                        <Button type='button' label='Exit' icon='pi pi-times' onClick={this.cancelAddCore} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className='p-grid p-justify-between'>
                    <div className='p-col-6'>
                    {this.state.secRole==='3' ? (<Button style={{ float:"left" }} type='button' label='Unassign' icon='pi pi-trash' onClick={this.unassignCore} />) : (null)}
                    </div>
                    <div className='p-col-6'>
                        <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCancel} />
                    </div>
                </div>
            );
        }
    }

    renderAssignCoreFooter() {
        return(
            <div>
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCancel} />
            </div>
        );
    }

    renderImgFooter() {
        return(
            <div>
                <div style={{float:'right'}}><Button label='Exit' icon='pi pi-times' onClick={this.closeEditImg} /></div>
                {this.state.secRole==='3' ? (<div style={{float:'right'}}><Button onClick={this.handleWebcam} label='Camera' icon='pi pi-camera'/></div>) : (null)}
                {this.state.secRole==='3' ? (<div style={{float:'right', marginRight:'7px'}}><FileUpload name="profile pic" key={this.state.inputKey} customUpload uploadHandler={this.myUploader} mode="basic" auto chooseLabel="Browse"/></div>) : (null)}
                {(this.state.isPlaceholder[this.state.editImgIndex] || this.state.secRole!=='3') ? (null) : (<div style={{float:'right'}}><Button label='Delete' icon='pi pi-trash' onClick={this.handleBlobDelete} /></div>)}
            </div>
        );
    }

    renderConfirmDelete() {
        return(
            <div>
                <div style={{float:'right'}}><Button label='Delete' icon='pi pi-trash' onClick={this.handleBlobDelete} /></div>
                <div style={{float:'right'}}><Button onClick={this.closeConfirmDelete} label='Cancel' icon='pi pi-times'/></div>
            </div>
        );
    }

    handleFieldChange(e) {
        if (e.target.id==='notes') {
            this.setState({ editNotes: e.target.value })
        } else if (e.target.id==='editDoorNum') {
            this.setState({ editDoorNum: e.target.value })
        } else if(e.target.id==='editDoorDesc') {
            this.setState({ editDoorDesc: e.target.value })
        } else if(e.target.id==='editArea') {
            this.setState({ editArea: e.target.value })
        } else if(e.target.id==='editType') {
            this.setState({ editType: e.target.value })
        } else if(e.target.id==='editCloserPN') {
            this.setState({ editCloserPN: e.target.value })
        } else if(e.target.id==='editCardReader') {
            this.setState({ editCardReader: e.value })
        } else if(e.target.id==='editStrike') {
            this.setState({ editStrike: e.value })
        } else if(e.target.id==='editLockPower') {
            this.setState({ editLockPower: e.value })
        } else if(e.target.id==='editSmartPack') {
            this.setState({ editSmartPack: e.value })
        } else if(e.target.id==='editDoorContact') {
            this.setState({ editDoorContact: e.value })
        } else if(e.target.id==='editHorn') {
            this.setState({ editHorn: e.value })
        } else if(e.target.id==='editREX') {
            this.setState({ editREX: e.value })
        } else if(e.target.id==='editRemoteRelease') {
            this.setState({ editRemoteRelease: e.value })
        } else if(e.target.id==='editDoorType') {
            this.setState({ editDoorType: e.value })
        } else if(e.target.id==='editHanding') {
            this.setState({ editHanding: e.value })
        } else if(e.target.id==='editHardware') {
            this.setState({ editHardware: e.value })
        } else if(e.target.id==='editFunction') {
            this.setState({ editFunction: e.value })
        } else if(e.target.id==='editCloserMfg') {
            this.setState({ editCloserMfg: e.value })
        } else if(e.target.id==='editThickness') {
            this.setState({ editThickness: e.value })
        } else if(e.target.id==='editBackSet') {
            this.setState({ editBackSet: e.value })
        } else if(e.target.id==='editDoorMaterial') {
            this.setState({ editDoorMaterial: e.value })
        } else if(e.target.id==='editFrameMaterial') {
            this.setState({ editFrameMaterial: e.value })
        } else if(e.target.id==='editPowerTransfer') {
            this.setState({ editPowerTransfer: e.value })
        } else if(e.target.id==='addCoreSelection') {
            this.setState({ addCoreSelection: e.value })
        } else if(e.target.id==='editPicDesc1') {
            this.setState({ editPicDesc1: e.target.value })
        } else if(e.target.id==='editPicDesc2') {
            this.setState({ editPicDesc2: e.target.value })
        } else if(e.target.id==='editPicDesc3') {
            this.setState({ editPicDesc3: e.target.value })
        } else if(e.target.id==='editPicDesc4') {
            this.setState({ editPicDesc4: e.target.value })
        } else if(e.target.id==='editPicDesc5') {
            this.setState({ editPicDesc5: e.target.value })
        } else if(e.target.id==='editPicDesc6') {
            this.setState({ editPicDesc6: e.target.value })
        } else if(e.target.id==='editPicDesc7') {
            this.setState({ editPicDesc7: e.target.value })
        } else if(e.target.id==='editPicDesc8') {
            this.setState({ editPicDesc8: e.target.value })
        }
    }

    render(){
        const { displayRowD } = this.state;
        const cardFooter1=<div><InputTextarea disabled={this.state.disabled} style={{display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain'}} id='editPicDesc1' value={this.state.editPicDesc1} onChange={(e) => this.handleFieldChange(e)} /></div>
        const cardFooter2=<div><InputTextarea disabled={this.state.disabled} style={{display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain'}} id='editPicDesc2' value={this.state.editPicDesc2} onChange={(e) => this.handleFieldChange(e)} /></div>
        const cardFooter3=<div><InputTextarea disabled={this.state.disabled} style={{display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain'}} id='editPicDesc3' value={this.state.editPicDesc3} onChange={(e) => this.handleFieldChange(e)} /></div>
        const cardFooter4=<div><InputTextarea disabled={this.state.disabled} style={{display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain'}} id='editPicDesc4' value={this.state.editPicDesc4} onChange={(e) => this.handleFieldChange(e)} /></div>
        const cardFooter5=<div><InputTextarea disabled={this.state.disabled} style={{display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain'}} id='editPicDesc5' value={this.state.editPicDesc5} onChange={(e) => this.handleFieldChange(e)} /></div>
        const cardFooter6=<div><InputTextarea disabled={this.state.disabled} style={{display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain'}} id='editPicDesc6' value={this.state.editPicDesc6} onChange={(e) => this.handleFieldChange(e)} /></div>
        const cardFooter7=<div><InputTextarea disabled={this.state.disabled} style={{display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain'}} id='editPicDesc7' value={this.state.editPicDesc7} onChange={(e) => this.handleFieldChange(e)} /></div>
        const cardFooter8=<div><InputTextarea disabled={this.state.disabled} style={{display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain'}} id='editPicDesc8' value={this.state.editPicDesc8} onChange={(e) => this.handleFieldChange(e)} /></div>
        const deleteFooter=this.renderDeleteFooter();
        const coreFooter = this.renderCoreFooter();
        const btnFooter = this.renderButtons();
        const editImgFooter = this.renderImgFooter();
        const confirmDeleteFooter = this.renderConfirmDelete();
        const assignCoreFooter = this.renderAssignCoreFooter();

        if(this.state.secRole==='0'&&this.props.isComponent && !this.state.componentIsLoading) {
            return(<div>Your security role prevents you from seeing the doors page</div>)
        } else if(this.state.secRole==='0' && this.props.isComponent===false && !this.state.componentIsLoading) {
            return(<Layout>Your security role prevents you from seeing the doors page</Layout>)
        } else if(this.state.data.length===0&&this.state.newDoor!==true && !this.state.componentIsLoading) {
            return(
                <div>
                    {this.props.isComponent ? (
                        <div>
                            {this.props.hasButton!==false ? (<div><Button style={{marginTop: '30px'}} type='button' label='New Door' icon='pi pi-plus' onClick={this.handleNewDoor}/></div>) : (null)}
                        </div>
                    ) : (
                        <Layout>
                            <div>
                                <div>Go to a building to add new doors</div>
                            </div>
                        </Layout>
                    )}
                </div>
            );
        } else {
            return(
                <div>
                    {this.props.isComponent ? 
                        (
                            <div className='container-div'>
                                <Messages ref={(el) => this.messages = el} />
                                    <div>
                                        <GeneralDatatable 
                                            columnArray = {[
                                                {field:'dooR_NUMBER', header:'Number'},
                                                {field:'area', header:'Area'},
                                                {field:'description', header:'Description'},
                                                {field:'type', header:'Type'},
                                                {field:'locatioN_NAME', header:'Building'},
                                                {field:'corE_LIST', header:'Cores Assigned'}
                                            ]}

                                            csvHeader = {[
                                                {key:'dooR_NUMBER', label:'Number'},
                                                {key:'area', label:'Name'},
                                                {key:'description', label:'Description'},
                                                {key:'type', label:'Type'},
                                                {key:'locatioN_NAME', label:'Building'},
                                                {key:'corE_LIST', label:'Cores Assigned'}
                                            ]}
                                            
                                            defaultOrder={["dooR_NUMBER", "area", "description", 'type', "locatioN_NAME", 'corE_LIST' ]}

                                            tableRecords = {this.state.data}
                                            pageNumber={20}
                                            tableName={this.props.header}
                                            exportFileName={'Doors.csv'}
                                            tableData={'doorsTableData'}
                                            filterData={'doorsFilterData'}
                                            columnWidthTracker={this.state.columnWidthTracker}
                                            isLoading={this.state.componentIsLoading}
                                            selectedColumns={this.state.selectedColumns}
                                            handleRowClick={this.editRecord}
                                            handleWidth={this.handleWidth}
                                            handleSelectedColumns={this.handleSelectedColumns}   
                                            hasButton={this.props.hasButton}
                                            buttonLabel={'New Door'}
                                            onButtonClick={this.handleNewDoor}
                                        />  
                                    </div>
                            </div>
                        ) : 
                        (
                            <Layout>
                                <div className='container-div'>
                                    <Messages ref={(el) => this.messages = el} />
                                    <GeneralDatatable 
                                        columnArray = {[
                                            {field:'dooR_NUMBER', header:'Number'},
                                            {field:'area', header:'Area'},
                                            {field:'description', header:'Description'},
                                            {field:'type', header:'Type'},
                                            {field:'locatioN_NAME', header:'Building'},
                                            {field:'corE_LIST', header:'Cores Assigned'}
                                        ]}

                                        csvHeader = {[
                                            {key:'dooR_NUMBER', label:'Number'},
                                            {key:'area', label:'Name'},
                                            {key:'description', label:'Description'},
                                            {key:'type', label:'Type'},
                                            {key:'locatioN_NAME', label:'Building'},
                                            {key:'corE_LIST', label:'Cores Assigned'}
                                        ]}
                                        
                                        defaultOrder={["dooR_NUMBER", "area", "description", 'type', "locatioN_NAME", 'corE_LIST' ]}

                                        tableRecords = {this.state.data}
                                        pageNumber={20}
                                        tableName={this.props.header}
                                        exportFileName={'Doors.csv'}
                                        tableData={'doorsTableData'}
                                        filterData={'doorsFilterData'}
                                        columnWidthTracker={this.state.columnWidthTracker}
                                        isLoading={this.state.componentIsLoading}
                                        selectedColumns={this.state.selectedColumns}
                                        handleRowClick={this.editRecord}
                                        handleWidth={this.handleWidth}
                                        handleSelectedColumns={this.handleSelectedColumns}   
                                        hasButton={false}
                                    />    
                                </div>
                            </Layout>
                        )}
                    <div>
                        <Dialog header={'Edit ' + this.state.editDoorNum} visible={displayRowD} onHide={this.handleClose} footer={btnFooter} closable={false} maximized baseZIndex={1300} blockScroll focusOnShow={false}>
                            <div style={{ width: '99vw', maxWidth:'100%'}}>
                                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                    <TabPanel header='Info' style={{margin:'auto'}}>
                                        <BrowserView>
                                            <div className='p-d-flex p-jc-evenly' >
                                                <div>
                                                    <h4>General</h4>
                                                    <TextBox value={this.state.editDoorNum} id="editDoorNum" label={"Door Number: "} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <TextBox value={this.state.editDoorDesc} id="editDoorDesc" label={"Description: "} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <TextBox value={this.state.editArea} id="editArea" label={"Area: "} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editArea} id="editArea" label={"Or: "} options={this.state.areaListOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <TextBox value={this.state.editType} id="editType" label={"Type: "} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editDoorType} id="editDoorType" label={"Door Type: "} options={doorTypeOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editHanding} id="editHanding" label={"Handing: "} options={handingOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editHardware} id="editHardware" label={"Hardware: "} options={hardwareOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editFunction} id="editFunction" label={"Function: "} options={functionOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                </div>
                                                <div style={{marginTop:'60px'}}>
                                                    <InputSelect value={this.state.editCloserMfg} id="editCloserMfg" label={"Closer Mfg: "} options={closerMFGOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <TextBox value={this.state.editCloserPN} id="editCloserPN" label={"Closer P/N: "} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editThickness} id="editThickness" label={"Door Thickness: "} options={thicknessOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editBackSet} id="editBackSet" label={"Back Set: "} options={backSetOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editDoorMaterial} id="editDoorMaterial" label={"Door Material: "} options={doorMatOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editFrameMaterial} id="editFrameMaterial" label={"Frame Material: "} options={frameMatOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <TextBox value={this.state.editNotes} id="notes" label={"Notes: "} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} area rows={5} />
                                                </div>
                                                <div>
                                                    <h4>Electrical Access Control</h4>
                                                    <InputSelect value={this.state.editCardReader} id="editCardReader" label={"Card Reader: "} options={cardReaderOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editStrike} id="editStrike" label={"Electric Strike: "} options={yesNoOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editLockPower} id="editLockPower" label={"Lock Power: "} options={lockPowerOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editSmartPack} id="editSmartPack" label={"Smart Pack: "} options={yesNoOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editDoorContact} id="editDoorContact" label={"Door Contact: "} options={yesNoOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editHorn} id="editHorn" label={"Horn: "} options={yesNoOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editREX} id="editREX" label={"REX: "} options={rexOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editRemoteRelease} id="editRemoteRelease" label={"Remote Release: "} options={remoteReleaseOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                    <InputSelect value={this.state.editPowerTransfer} id="editPowerTransfer" label={"Power Transfer: "} options={powerTransferOptions} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                                </div>
                                            </div>
                                            <div className='p-d-flex p-jc-center' style={{marginTop:'75px' }} >
                                                <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter1}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[0]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(0)} /></div></Card>
                                                <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter2}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[1]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(1)} /></div></Card>
                                                <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter3}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[2]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(2)} /></div></Card>
                                                <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter4}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[3]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(3)} /></div></Card>
                                                <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter5}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[4]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(4)} /></div></Card>
                                                <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter6}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[5]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(5)} /></div></Card>
                                                <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter7}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[6]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(6)} /></div></Card>
                                                <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter8}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[7]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(7)} /></div></Card>
                                            </div>
                                        </BrowserView>
                                        <MobileView>
                                            <div className='p-grid'>
                                                <TextBox value={this.state.editDoorNum} id="editDoorNum" label={"Door Number: "} handleFieldChange={this.handleFieldChange} mobile />
                                                <TextBox value={this.state.editDoorDesc} id="editDoorDesc" label={"Description: "} handleFieldChange={this.handleFieldChange} mobile />
                                                <TextBox value={this.state.editArea} id="editArea" label={"Area: "} handleFieldChange={this.handleFieldChange} mobile />
                                                <InputSelect value={this.state.editArea} id="editAreaOr" label={"Or: "} options={this.state.areaListOptions} handleFieldChange={this.handleFieldChange} mobile/>
                                                <TextBox value={this.state.editType} id="editType" label={"Type: "} handleFieldChange={this.handleFieldChange} mobile />
                                                <InputSelect value={this.state.editDoorType} id="editDoorType" label={"Door Type: "} options={doorTypeOptions} handleFieldChange={this.handleFieldChange} mobile/>
                                                <InputSelect value={this.state.editHanding} id="editHanding" label={"Handing: "} options={handingOptions} handleFieldChange={this.handleFieldChange} mobile/>
                                                <InputSelect value={this.state.editHardware} id="editHardware" label={"Hardware: "} options={hardwareOptions} handleFieldChange={this.handleFieldChange} mobile/>
                                                <InputSelect value={this.state.editFunction} id="editFunction" label={"Function: "} options={functionOptions} handleFieldChange={this.handleFieldChange} mobile/>
                                                <InputSelect value={this.state.editCloserMfg} id="editCloserMfg" label={"Closer Mfg: "} options={closerMFGOptions} handleFieldChange={this.handleFieldChange} mobile/>
                                                <TextBox value={this.state.editCloserPN} id="editCloserPN" label={"Closer P/N: "} handleFieldChange={this.handleFieldChange} mobile />
                                                <InputSelect value={this.state.editThickness} id="editThickness" label={"Door Thickness: "} options={thicknessOptions} handleFieldChange={this.handleFieldChange} mobile/>
                                                <InputSelect value={this.state.editBackSet} id="editBackSet" label={"Back Set: "} options={backSetOptions} handleFieldChange={this.handleFieldChange} mobile/>
                                                <InputSelect value={this.state.editDoorMaterial} id="editDoorMaterial" label={"Door Material: "} options={doorMatOptions} handleFieldChange={this.handleFieldChange} mobile/>
                                                <InputSelect value={this.state.editFrameMaterial} id="editFrameMaterial" label={"Frame Material: "} options={frameMatOptions} handleFieldChange={this.handleFieldChange} mobile/>
                                                <TextBox value={this.state.editNotes} id="notes" label={"Notes: "} handleFieldChange={this.handleFieldChange} mobile area rows={15} disabled={this.state.disabled} />

                                                <Card style={{ overflow:'hidden' }} footer={cardFooter1}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[0]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(0)} /></div></Card>
                                                <Card style={{ overflow:'hidden' }} footer={cardFooter2}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[1]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(1)} /></div></Card>
                                                <Card style={{ overflow:'hidden' }} footer={cardFooter3}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[2]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(2)} /></div></Card>
                                                <Card style={{ overflow:'hidden' }} footer={cardFooter4}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[3]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(3)} /></div></Card>
                                                <Card style={{ overflow:'hidden' }} footer={cardFooter5}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[4]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(4)} /></div></Card>
                                                <Card style={{ overflow:'hidden' }} footer={cardFooter6}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[5]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(5)} /></div></Card>
                                                <Card style={{ overflow:'hidden' }} footer={cardFooter7}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[6]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(6)} /></div></Card>
                                                <Card style={{ overflow:'hidden' }} footer={cardFooter8}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[7]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(7)} /></div></Card>
                                            </div>
                                        </MobileView>
                                    </TabPanel>
                                    <TabPanel header='Installed Cores'>
                                        <DoorCoreDatatable disabled={this.state.disabled} coreData={this.state.coreData} unassignCore={this.unassignCore} assignCore={this.assignCore} />
                                    </TabPanel>
                                    <TabPanel header='Issued Keys That Can Open'>
                                        <KeyDatatable editKeyMarkId={0} keyData={this.state.keyData} />
                                    </TabPanel>
                                    <TabPanel header='Door History'>
                                        <DoorHistDatatable doorHistData={this.state.doorHistData} />
                                    </TabPanel>
                                </TabView>
                            </div>
                        </Dialog>
                        <Dialog header={'New Door'} visible={this.state.newDoor} onHide={this.handleClose} footer={btnFooter} closable={false} maximized baseZIndex={1300} blockScroll focusOnShow={false}>
                            <div style={{ width: '99vw', maxWidth:'100%'}}>
                                <BrowserView>
                                    <div className='p-d-flex p-jc-evenly' >
                                        <div>
                                            <h4>General</h4>
                                            <TextBox value={this.state.editDoorNum} id="editDoorNum" label={"Door Number: "} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <TextBox value={this.state.editDoorDesc} id="editDoorDesc" label={"Description: "} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <TextBox value={this.state.editArea} id="editArea" label={"Area: "} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <InputSelect value={this.state.editArea} id="editArea" label={"Or: "} options={this.state.areaListOptions} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <TextBox value={this.state.editType} id="editType" label={"Type: "} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <InputSelect value={this.state.editDoorType} id="editDoorType" label={"Door Type: "} options={doorTypeOptions} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <InputSelect value={this.state.editHanding} id="editHanding" label={"Handing: "} options={handingOptions} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <InputSelect value={this.state.editHardware} id="editHardware" label={"Hardware: "} options={hardwareOptions} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <InputSelect value={this.state.editFunction} id="editFunction" label={"Function: "} options={functionOptions} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                        </div>
                                        <div style={{marginTop:'60px'}}>
                                            <InputSelect value={this.state.editCloserMfg} id="editCloserMfg" label={"Closer Mfg: "} options={closerMFGOptions} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <TextBox value={this.state.editCloserPN} id="editCloserPN" label={"Closer P/N: "} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <InputSelect value={this.state.editThickness} id="editThickness" label={"Door Thickness: "} options={thicknessOptions} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <InputSelect value={this.state.editBackSet} id="editBackSet" label={"Back Set: "} options={backSetOptions} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <InputSelect value={this.state.editDoorMaterial} id="editDoorMaterial" label={"Door Material: "} options={doorMatOptions} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <InputSelect value={this.state.editFrameMaterial} id="editFrameMaterial" label={"Frame Material: "} options={frameMatOptions} handleFieldChange={this.handleFieldChange}  disabled={this.state.disabled} />
                                            <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                                                <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor="notes"><strong>Notes: </strong></label>
                                                <div className='p-col-fixed'><InputTextarea style={{width:'275px'}} value={this.state.editNotes} onChange={(e) => this.handleFieldChange(e)} id='notes' rows={4}  disabled={this.state.disabled} /></div>
                                            </div>
                                        </div>
                                        <div>
                                            <h4>Electrical Access Control</h4>
                                            <InputSelect disabled={this.state.disabled} value={this.state.editCardReader} id="editCardReader" label={"Card Reader: "} options={cardReaderOptions} handleFieldChange={this.handleFieldChange} />
                                            <InputSelect disabled={this.state.disabled} value={this.state.editStrike} id="editStrike" label={"Electric Strike: "} options={yesNoOptions} handleFieldChange={this.handleFieldChange} />
                                            <InputSelect disabled={this.state.disabled} value={this.state.editLockPower} id="editLockPower" label={"Lock Power: "} options={lockPowerOptions} handleFieldChange={this.handleFieldChange} />
                                            <InputSelect disabled={this.state.disabled} value={this.state.editSmartPack} id="editSmartPack" label={"Smart Pack: "} options={yesNoOptions} handleFieldChange={this.handleFieldChange} />
                                            <InputSelect disabled={this.state.disabled} value={this.state.editDoorContact} id="editDoorContact" label={"Door Contact: "} options={yesNoOptions} handleFieldChange={this.handleFieldChange} />
                                            <InputSelect disabled={this.state.disabled} value={this.state.editHorn} id="editHorn" label={"Horn: "} options={yesNoOptions} handleFieldChange={this.handleFieldChange} />
                                            <InputSelect disabled={this.state.disabled} value={this.state.editREX} id="editREX" label={"REX: "} options={rexOptions} handleFieldChange={this.handleFieldChange} />
                                            <InputSelect disabled={this.state.disabled} value={this.state.editRemoteRelease} id="editRemoteRelease" label={"Remote Release: "} options={remoteReleaseOptions} handleFieldChange={this.handleFieldChange} />
                                            <InputSelect disabled={this.state.disabled} value={this.state.editPowerTransfer} id="editPowerTransfer" label={"Power Transfer: "} options={powerTransferOptions} handleFieldChange={this.handleFieldChange} />
                                        </div>
                                    </div>
                                    <div className='p-d-flex p-jc-center' style={{marginTop:'75px' }} >
                                        <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter1}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[0]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(0)} /></div></Card>
                                        <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter2}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[1]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(1)} /></div></Card>
                                        <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter3}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[2]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(2)} /></div></Card>
                                        <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter4}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[3]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(3)} /></div></Card>
                                        <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter5}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[4]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(4)} /></div></Card>
                                        <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter6}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[5]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(5)} /></div></Card>
                                        <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter7}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[6]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(6)} /></div></Card>
                                        <Card style={{ overflow:'hidden', width:'20vw' }} footer={cardFooter8}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[7]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(7)} /></div></Card>
                                    </div>
                                </BrowserView>
                                <MobileView>
                                    <div className='p-grid'>
                                        <TextBox value={this.state.editDoorNum} id="editDoorNum" label={"Door Number: "} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <TextBox value={this.state.editDoorDesc} id="editDoorDesc" label={"Description: "} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <TextBox value={this.state.editArea} id="editArea" label={"Area: "} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <InputSelect value={this.state.editArea} id="editAreaOr" label={"Or: "} options={this.state.areaListOptions} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <TextBox value={this.state.editType} id="editType" label={"Type: "} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <InputSelect value={this.state.editDoorType} id="editDoorType" label={"Door Type: "} options={doorTypeOptions} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <InputSelect value={this.state.editHanding} id="editHanding" label={"Handing: "} options={handingOptions} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <InputSelect value={this.state.editHardware} id="editHardware" label={"Hardware: "} options={hardwareOptions} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <InputSelect value={this.state.editFunction} id="editFunction" label={"Function: "} options={functionOptions} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <InputSelect value={this.state.editCloserMfg} id="editCloserMfg" label={"Closer Mfg: "} options={closerMFGOptions} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <TextBox value={this.state.editCloserPN} id="editCloserPN" label={"Closer P/N: "} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <InputSelect value={this.state.editThickness} id="editThickness" label={"Door Thickness: "} options={thicknessOptions} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <InputSelect value={this.state.editBackSet} id="editBackSet" label={"Back Set: "} options={backSetOptions} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <InputSelect value={this.state.editDoorMaterial} id="editDoorMaterial" label={"Door Material: "} options={doorMatOptions} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <InputSelect value={this.state.editFrameMaterial} id="editFrameMaterial" label={"Frame Material: "} options={frameMatOptions} handleFieldChange={this.handleFieldChange} mobile disabled={this.state.disabled} />
                                        <TextBox value={this.state.editNotes} id="notes" label={"Notes: "} handleFieldChange={this.handleFieldChange} mobile area rows={15} disabled={this.state.disabled} />

                                        <Card style={{ overflow:'hidden' }} footer={cardFooter1}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[0]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(0)} /></div></Card>
                                        <Card style={{ overflow:'hidden' }} footer={cardFooter2}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[1]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(1)} /></div></Card>
                                        <Card style={{ overflow:'hidden' }} footer={cardFooter3}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[2]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(2)} /></div></Card>
                                        <Card style={{ overflow:'hidden' }} footer={cardFooter4}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[3]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(3)} /></div></Card>
                                        <Card style={{ overflow:'hidden' }} footer={cardFooter5}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[4]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(4)} /></div></Card>
                                        <Card style={{ overflow:'hidden' }} footer={cardFooter6}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[5]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(5)} /></div></Card>
                                        <Card style={{ overflow:'hidden' }} footer={cardFooter7}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[6]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(6)} /></div></Card>
                                        <Card style={{ overflow:'hidden' }} footer={cardFooter8}><div style={{ overflow:'hidden' }}><img alt='door pic' src={this.state.doorPicSrc[7]} style={{ display:'flex', width:'100%', height:'100%', overflow:'hidden', objectFit:'contain' }} onClick={() => this.editImage(7)} /></div></Card>
                                    </div>
                                </MobileView>
                            </div>
                        </Dialog>
                        <Dialog header={'Delete ' + this.state.editDoorNum} baseZIndex={1400} visible={this.state.pendingDelete} onHide={this.cancelDelete} footer={deleteFooter} blockScroll focusOnShow={false} closable={false}>
                            {this.state.coreData.length>0 ? 
                            (<div><p>You cannot delete this door with cores assigned to it</p></div>) : 
                            (
                                <div>
                                    <p>Are you sure you would like to delete '{this.state.editDoorNum}'?</p>
                                </div>
                            )}
                        </Dialog>
                        <Dialog visible={this.state.editImgDialog} onHide={this.closeEditImg} footer={editImgFooter} baseZIndex={1400} blockScroll focusOnShow={false} maximized responsive closable={false}>
                            <div>
                                <div className='p-grid p-justify-center'>
                                    {this.state.webcamOn ? 
                                        (<div className='p-col-12'>
                                            {this.state.isPicLoading ? (<div><ProgressSpinner /></div>) : (
                                                <div><div><BrowserView><Webcam audio={false} ref={this.setWebcamRef} screenshotFormat="image/jpeg" style={{maxWidth: '300px', maxHeight:'400px'}}/></BrowserView></div>
                                                <div><MobileView><Webcam videoConstraints={videoConstraints} audio={false} ref={this.setWebcamRef} screenshotFormat="image/jpeg" style={{maxWidth: '300px', maxHeight:'400px'}}/></MobileView></div></div>
                                            )}
                                            <Button label='Capture' icon='pi pi-camera' onClick={this.capture} style={{ marginRight: '10px' }}/>
                                            <Button label='Exit' onClick={this.cancelWebcam} />
                                        </div>) : 
                                        
                                        (<div className='p-col-12'>
                                            <BrowserView>
                                                {this.state.isPicLoading ? (<div><ProgressSpinner /></div>) : (<img alt='doorpic' src={this.state.doorPicSrc[this.state.editImgIndex]} style={{ height:'75vh', width:'auto', maxWidth:'100%' }} />)}
                                            </BrowserView>
                                            <MobileView>
                                                {this.state.isPicLoading ? (<div><ProgressSpinner /></div>) : (<img alt='doorpic' src={this.state.doorPicSrc[this.state.editImgIndex]} style={{ height:'auto', width:'auto', maxWidth:'100%' }} />)}
                                            </MobileView>
                                        </div>)
                                    }

                                    <div className='p-col-6 p-grid'>
                                        {this.state.editImgIndex===0 ? (<div className='p-col-12 p-fluid'>{this.state.editPicDesc1}</div>) : (null)}
                                        {this.state.editImgIndex===1 ? (<div className='p-col-12 p-fluid'>{this.state.editPicDesc2}</div>) : (null)}
                                        {this.state.editImgIndex===2 ? (<div className='p-col-12 p-fluid'>{this.state.editPicDesc3}</div>) : (null)}
                                        {this.state.editImgIndex===3 ? (<div className='p-col-12 p-fluid'>{this.state.editPicDesc4}</div>) : (null)}
                                        {this.state.editImgIndex===4 ? (<div className='p-col-12 p-fluid'>{this.state.editPicDesc5}</div>) : (null)}
                                        {this.state.editImgIndex===5 ? (<div className='p-col-12 p-fluid'>{this.state.editPicDesc6}</div>) : (null)}
                                        {this.state.editImgIndex===6 ? (<div className='p-col-12 p-fluid'>{this.state.editPicDesc7}</div>) : (null)}
                                        {this.state.editImgIndex===7 ? (<div className='p-col-12 p-fluid'>{this.state.editPicDesc8}</div>) : (null)}
                                    </div>
                                </div>
                            </div>
                            <Dialog visible={this.state.confirmDelete} onHide={this.closeConfirmDelete} footer={confirmDeleteFooter} baseZIndex={1400} blockScroll focusOnShow={false} responsive closable={false}>
                                Are you sure you would like to delete this image?
                            </Dialog>
                        </Dialog>
                        <Dialog header={'Core ' + this.state.editCoreMark} visible={this.state.coreEdit} onHide={this.handleCancel} footer={coreFooter} baseZIndex={1400} blockScroll focusOnShow={false}>
                            {'Are you sure you want to unassign Core ' + this.state.editCoreMark + ' from door ' + this.state.editDoorNum + ' ?'}
                        </Dialog>
                        <Dialog header={'Door ' + this.state.editDoorNum} footer={assignCoreFooter} visible={this.state.addCoreDialog} onHide={this.handleCancel} baseZIndex={1400} blockScroll focusOnShow={false} maximized closable={false}>
                            <div>
                                <UnassignedCoresTable unassignedCores={this.state.unassignedCores} assignCore={this.addCore} />
                            </div>
                        </Dialog>
                        <Dialog visible={this.state.assignCoreDialog} footer={coreFooter} onHide={this.cancelAddCore} baseZIndex={1400} blockScroll focusOnShow={false} closable={false}>
                            <div>
                                <p>Do you want to assign core {this.state.coreName} to door?</p>
                            </div>
                        </Dialog>
                    </div>
                </div>
            );
        }
    }
}

export default DoorsComponent;