import axios from 'axios';
import { serverAddr } from './serverAddr';

export const TableService = {
  fetchTableState,
  saveTableState
}

export const SupportService = {
  getTickets,
  getTicketDetails,
  createTicket,
  createTicketDetail,
  updateTicket
}

export const RecordsService = {
    fetchRecords,
    FetchLazyRecords,
    storeRecord,
    fetchSystems,
    storeSystems,
    fetchCabinets,
    storeCabinet,
    fetchHooks,
    AddHooks,
    StoreHook,
    DeleteCabinet,
    ChangeCoremark,
    ChangeKeymark,
    FetchKeyLevels
};

export const RolesService = {
  fetchRoles,
  updateRoles,
  deleteRole

};

export const DeptService = {
  FetchDept,
  StoreDept,
  DeleteDept
};

export const DomainService = {
  fetchDomains,
  storeDomain
};

export const PeopleService = {
  fetchUsers,
  storeUser,
  deleteUser
}

export const JobsService = {
  fetchJobs,
  fetchJobDetails,
  addJobDetails,
  SaveJob,
  DeleteJob,
  CloseJob,
  updateJobDetail,
  MakeMarkCutFile
}

export const DeviceService = {
  fetchDevices,
  updateDevices
}

export const KeyService = {
  fetchIssuedKeys,
  saveKey,
  fetchUnissuedKeys,
  storeIssuedKey,
  IssueKey,
  ReturnKey,
  FetchKeyEvents,
  FetchLazyKeys
}

export const CoreService = {
  fetchPinnedCores,
  fetchCores,
  addCore,
  removeCore,
  saveCore
}

export const DoorService = {
  fetchDoors,
  storeDoor,
  deleteDoor,
  fetchDoorHist,
  fetchAreas,
  fetchGroups,
  fetchLocations,
  storeLocation,
  deleteLocation,
  storeGroup,
  deleteGroup

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchTableState( OrgID, User_ID, Page_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
      method: 'post',
      url: serverAddr.apiUrl + '/Grids/LoadLayout',
      headers: { 
        'Authorization': 'Bearer ' + bearerToken,
        'Content-Type': 'application/json'
      },
      
      data : JSON.stringify({ OrgID, User_ID, Page_ID })
    };
    
    try {
      const response = await axios(config)
      // console.log(response)
      if(response.data.layout.rows===0 || response.data.layout.columnOrder===null || response.data.layout.selectedColumns===null || response.data.layout.columnWidths==="") {
        return false;
      } else if (response.data.layout.page_ID===6) {
        sessionStorage.setItem('recordsTableData', JSON.stringify(response.data.layout))
      } else if (response.data.layout.page_ID===7) {
        sessionStorage.setItem('jobsTableData', JSON.stringify(response.data.layout))
      } else if (response.data.layout.page_ID===15) {
        sessionStorage.setItem('rolesTableData', JSON.stringify(response.data.layout))
      } else if (response.data.layout.page_ID===2) {
        sessionStorage.setItem('peopleTableData', JSON.stringify(response.data.layout))
      } else if (response.data.layout.page_ID===3) {
        sessionStorage.setItem('keysTableData', JSON.stringify(response.data.layout))
      } else if (response.data.layout.page_ID===22) {
        sessionStorage.setItem('devicesTableData', JSON.stringify(response.data.layout))
      } else if (response.data.layout.page_ID===23) {
        sessionStorage.setItem('keyDatatableTableData', JSON.stringify(response.data.layout))
      } else if (response.data.layout.page_ID===24) {
        sessionStorage.setItem('PeopleKeyDatatableTableData', JSON.stringify(response.data.layout))
      } else if (response.data.layout.page_ID===20) {
        sessionStorage.setItem('doorsTableData', JSON.stringify(response.data.layout))
      } else if (response.data.layout.page_ID===9) {
        sessionStorage.setItem('buildingGroupsTableData', JSON.stringify(response.data.layout))
      }  else if (response.data.layout.page_ID===4) {
        sessionStorage.setItem('buildingsTableData', JSON.stringify(response.data.layout))
      }  else if (response.data.layout.page_ID===31) {
        sessionStorage.setItem('jobDetailsTableData', JSON.stringify(response.data.layout))
      }  else if (response.data.layout.page_ID===11) {
        sessionStorage.setItem('systemsTableData', JSON.stringify(response.data.layout))
      }  else if (response.data.layout.page_ID===35) {
        sessionStorage.setItem('KeyHistoryTableData', JSON.stringify(response.data.layout))
      }  else if (response.data.layout.page_ID===37) {
        sessionStorage.setItem('supportCasesTableData', JSON.stringify(response.data.layout))
      }  else if (response.data.layout.page_ID===38) {
        sessionStorage.setItem('supportCaseDetailsTableData', JSON.stringify(response.data.layout))
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
}

async function saveTableState(orgID, user_ID, page_ID, rows, columnWidths, columnOrder, selectedColumns) {
let userData = sessionStorage.getItem('userData');
let parsedUserData = JSON.parse(userData);
let bearerToken = parsedUserData.token;

const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Grids/SaveLayout',
  headers: { 
    'Authorization': 'Bearer ' + bearerToken,
    'Content-Type': 'application/json'
  },
  
  data : JSON.stringify({ orgID, user_ID, page_ID, rows, columnWidths, columnOrder, selectedColumns })
};

try {
  // console.log('CONFIG', config.data)
  const response = await axios(config)
  // console.log('RESPONSE', response.data)
  if(response.data.success) {
    return true;
  } else {
    return false;
  }
} catch (error) {
  console.log(error);
  return false;
}
}

///////////////////////////////////////////////////////////////////////////////

async function fetchRecords( OrgID, RecordID, system ) {
    let userData = sessionStorage.getItem('userData');
    let parsedUserData = JSON.parse(userData);
    let bearerToken = parsedUserData.token;

    const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/Records/FetchRecords',
    headers: { 
        'Authorization': 'Bearer ' + bearerToken,
        'Content-Type': 'application/json'
    },
    data : JSON.stringify({ OrgID, RecordID, system })    
};

    try {
      // console.log('CONFIG', config)
        const response = await axios(config)
        // console.log('RESPONSE', response.data)
        return response.data;
    } catch (error) {
        console.log(error);
        return false;
    }
}

async function FetchLazyRecords(payload) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/FetchLazyRecords_Kurt',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify(payload)    
};

  try {
    // console.log('CONFIG', config)
      const response = await axios(config)
      // console.log('RESPONSE', response.data)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function FetchLazyKeys(payload) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/users/fetchlazykeys',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify(payload)    
};

  try {
    // console.log('CONFIG', config)
      const response = await axios(config)
      // console.log('RESPONSE', response.data)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function storeRecord( OrgID, RecordID, DESCRIPTION, NOTES ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/StoreRecord',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ OrgID, RecordID, DESCRIPTION, NOTES })    
};

  try {
      const response = await axios(config)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function fetchSystems( UIS_M1ORGID, UIS_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl +  '/Records/FetchSystems',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UIS_M1ORGID, UIS_ID })    
};

  try {
    // console.log('CONFIG', config)
      const response = await axios(config)
      // console.log('RESPONSE', response.data)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function storeSystems( UIS_M1ORGID, UIS_ID, UIS_ASSIGNED, UIS_DESC, UIS_NOTES ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/StoreSystem',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UIS_M1ORGID, UIS_ID, UIS_ASSIGNED, UIS_DESC, UIS_NOTES })    
};

  try {
      const response = await axios(config)
      // console.log("CONFIG", config)
      // console.log("RESPONSE", response)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function fetchCabinets( UIB_M1ORGID, UIB_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/FetchCabinets',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UIB_M1ORGID, UIB_ID })    
};

  try {
    // console.log('CONFIG FETCH', config)
      const response = await axios(config)
      // console.log('RESPONSE FETCH', response.data)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function storeCabinet( uiB_ID, uiB_UNIQUEID, uiB_NAME, uiB_DESC, uiB_NOTES, uiB_M1ORGID, uiB_UID, uiB_NUMHOOKS, uiB_DELETED, uiB_LOCATIONID, caB_TYPE_ID, caB_MODEL, caB_SN, caB_PHYSICAL_LOC, caB_TYPE_NAME, caB_LOC ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/StoreCabinet',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ uiB_ID, uiB_UNIQUEID, uiB_NAME, uiB_DESC, uiB_NOTES, uiB_M1ORGID, uiB_UID, uiB_NUMHOOKS, uiB_DELETED, uiB_LOCATIONID, caB_TYPE_ID, caB_MODEL, caB_SN, caB_PHYSICAL_LOC, caB_TYPE_NAME, caB_LOC })    
};

  try {
      // console.log("CONFIG STORE", config.data)
      const response = await axios(config)
      // console.log("RESPONSE STORE", response)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function DeleteCabinet( uiB_ID, uiB_M1ORGID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/DeleteCabinet',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ uiB_ID, uiB_M1ORGID })    
};

  try {
      // console.log("CONFIG STORE", config.data)
      const response = await axios(config)
      // console.log("RESPONSE STORE", response)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function ChangeKeymark( orgID, keyMark, keyMarkID, system ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/ChangeKeymark',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ orgID, keyMark, keyMarkID, system })    
};

  try {
      // console.log("CONFIG STORE", config.data)
      const response = await axios(config)
      // console.log("RESPONSE STORE", response)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function ChangeCoremark( orgID, CoreMark, CoreMarkID, system ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/ChangeCoremark',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ orgID, CoreMark, CoreMarkID, system })    
};

  try {
      // console.log("CONFIG STORE", config.data)
      const response = await axios(config)
      // console.log("RESPONSE STORE", response)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function FetchKeyLevels(orgID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/FetchKeyLevels',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ orgID })    
};

  try {
      // console.log("CONFIG STORE", config.data)
      const response = await axios(config)
      // console.log("RESPONSE STORE", response)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function fetchHooks( UIT_M1ORGID, UIT_CABINETID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/FetchHooks',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UIT_M1ORGID, UIT_CABINETID })    
};

  try {
    // console.log('CONFIG', config)
      const response = await axios(config)
      // console.log('RESPONSE', response.data)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function AddHooks( UIB_M1ORGID, UIB_ID, UIB_NUMHOOKS ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/AddHooks',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UIB_M1ORGID, UIB_ID, UIB_NUMHOOKS })    
};

  try {
    // console.log('CONFIG', config)
      const response = await axios(config)
      // console.log('RESPONSE', response.data)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function StoreHook( UIT_M1ORGID, UIT_ID, UIT_DESC ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/StoreHook',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UIT_M1ORGID, UIT_ID, UIT_DESC })    
};

  try {
    // console.log('CONFIG', config)
      const response = await axios(config)
      // console.log('RESPONSE', response.data)
      return response.data;
  } catch (error) {
      console.log(error);
      return false;
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchRoles(OrgId) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/Roles/FetchRoles',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    
    data : JSON.stringify({ OrgId })
  };
  
  try {
    const response = await axios(config)
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function updateRoles(uiS_ID, uiS_NAME, uiS_NOTES, uiS_M1ORGID, uiS_SECSTR) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/Roles/SaveRole',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    
    data : JSON.stringify({ uiS_ID, uiS_NAME, uiS_NOTES, uiS_M1ORGID, uiS_SECSTR })
  };

  try {
    const response = await axios(config)
    if(response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }

}

async function deleteRole(uiS_ID, uiS_M1ORGID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/roles/DeleteRole',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    
    data : JSON.stringify({ uiS_ID, uiS_M1ORGID })
  }

  try {
    const response = await axios(config)
    return response;
  } catch (error) {
    console.log(error)
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchDomains(ORG_ID, DOMAIN_ID, REQ_ID, REQ_NAME, GUID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/Domains/FetchDomains',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    
    data : JSON.stringify({ ORG_ID, DOMAIN_ID, REQ_ID, REQ_NAME, GUID })
  };
  
  try {
    const response = await axios(config)
    // console.log(config.data)
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function storeDomain(DOMAIN, DESCRIPTION, PARENT_ID, NOTES, ORG_ID, DOMAIN_ID, REQ_ID, REQ_NAME, GUID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/StoreDomain',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    
    data : JSON.stringify({DOMAIN, DESCRIPTION, PARENT_ID, NOTES, ORG_ID, DOMAIN_ID, REQ_ID, REQ_NAME, GUID})
  };
  
  try {
    const response = await axios(config)
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchUsers(OrgId, UIU_GUID, ID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/fetchusers',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    
    data : JSON.stringify({ OrgId, UIU_GUID, ID })
  };
  
  try {
    const response = await axios(config)
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function storeUser(UIU_ID, UIU_M1ORGID, uiU_FN, uiU_LN, uiU_EMAIL, UIU_DEPTID, uiU_TITLE, uiU_PHONE, uiU_EMPLOYEEID, UIU_SECROLEID, uiU_NOTES, UIU_DOMAINID, UIU_GUID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/StoreUser',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    
    data : JSON.stringify({ UIU_ID, UIU_M1ORGID, uiU_FN, uiU_LN, uiU_EMAIL, UIU_DEPTID, uiU_TITLE, uiU_PHONE, uiU_EMPLOYEEID, UIU_SECROLEID, uiU_NOTES, UIU_DOMAINID, UIU_GUID })
  };
  
  try {
    // console.log(config.data)
    const response = await axios(config)
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function deleteUser(UIU_ID, UIU_M1ORGID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/DeleteUser',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    
    data : JSON.stringify({ UIU_ID, UIU_M1ORGID })
  };
  
  try {
    // console.log(config.data)
    const response = await axios(config)
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchJobs( WOH_M1ORGID, WOH_STATUS ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Jobs/FetchJobs',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ WOH_M1ORGID, WOH_STATUS })    
};

  try {
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function SaveJob( woH_ID, woH_UNIQUEID, woH_M1ORGID, woH_NOTES, woH_CREATEDDATE, woH_POSTEDDATE, woH_CREATEDBY, woH_REFNUM, woH_PO, woH_DUEDATE, woH_DESC, woH_COMPLETEDATE, woH_STATUS, woH_SYSNAME ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;
  // console.log('refnum', woH_REFNUM)
  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Jobs/SaveJob',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ woH_ID, woH_UNIQUEID, woH_M1ORGID, woH_NOTES, woH_CREATEDDATE, woH_POSTEDDATE, woH_CREATEDBY, woH_REFNUM, woH_PO, woH_DUEDATE, woH_DESC, woH_COMPLETEDATE, woH_STATUS, woH_SYSNAME })    
};

  try {
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function CloseJob( woH_ID, woH_M1ORGID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;
  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Jobs/CloseJob',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ woH_ID, woH_M1ORGID })    
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function updateJobDetail( WOD_M1ORGID, WOD_HEADERID, WOD_LINENUM, WOD_QTY ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;
  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Jobs/UpdateJobDetail',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({WOD_M1ORGID, WOD_HEADERID, WOD_LINENUM, WOD_QTY})    
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function MakeMarkCutFile( woH_ID, woH_M1ORGID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;
  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Jobs/MakeMarkCutFile',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({woH_ID, woH_M1ORGID})    
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function fetchJobDetails( WOD_M1ORGID, WOD_HEADERID, WOD_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Jobs/FetchJobDetails',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ WOD_M1ORGID, WOD_HEADERID, WOD_ID })
  // data : {WOH_M1ORGID: "demo", WOD_HEADERID: 2, WOD_ID: 0}
};

  try {
      // console.log("CONFIG", config)
      const response = await axios(config)
      // console.log('RESPONSE', response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function addJobDetails( WOD_HEADERID, WOD_M1ORGID, ListOfDetails ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Jobs/AddJobDetails',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ WOD_HEADERID, WOD_M1ORGID, ListOfDetails })    
};

  try {
      // console.log('CONFIG', config.data)
      const response = await axios(config)
      // console.log('RESPONSE', response)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function DeleteJob( WOH_M1ORGID, WOH_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Jobs/DeleteJob',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ WOH_M1ORGID, WOH_ID })    
};

  try {
      const response = await axios(config)
      // console.log('job details', response)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchDevices( M1ORGID, DEVICE_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/ML/FetchDevices',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ M1ORGID, DEVICE_ID })    
};

  try {
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function updateDevices( m1ORGID, devicE_ID, devicE_NAME, devicE_NOTES, locatioN_ID, description, area ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/ML/SaveDevice',
    headers: { 
        'Authorization': 'Bearer ' + bearerToken,
        'Content-Type': 'application/json'
    },
    data : JSON.stringify({ m1ORGID, devicE_ID, devicE_NAME, devicE_NOTES, locatioN_ID, description, area })    
  };

  try {
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchIssuedKeys( UIY_M1ORGID, UIY_EMPLOYEEID, UIY_KEYRECORDID, DOOR_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/users/FetchIssuedKeys',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UIY_M1ORGID, UIY_EMPLOYEEID, UIY_KEYRECORDID, DOOR_ID })
};

  try {
      const response = await axios(config)
      // console.log('RESPONSE', response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function saveKey( uiY_M1ORGID, UIY_ID, UIY_PRODUCT, UIY_NOTES, UIY_DESC, UIY_CABINETID, UIY_HOOKNUMBER ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/SaveKey',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ uiY_M1ORGID, UIY_ID, UIY_PRODUCT, UIY_NOTES, UIY_DESC, UIY_CABINETID, UIY_HOOKNUMBER })
};

  try {
      const response = await axios(config)
      // console.log('RESPONSE', config.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchPinnedCores( M1ORGID, SYSTEM, CORE_MARK_ID, CORE_TYPE ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Cores/FetchPinning',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ M1ORGID, SYSTEM, CORE_MARK_ID, CORE_TYPE })
};

  try {
    // console.log(config)
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function fetchCores( CORE_MARK_ID, ORG_ID, REQ_ID, REQ_NAME, DOOR_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/FetchCores',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ CORE_MARK_ID, ORG_ID, REQ_ID, REQ_NAME, DOOR_ID })    
};

  try {
      const response = await axios(config)
      // console.log(response)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function addCore( ORG_ID, REQ_ID, REQ_NAME, DOOR_ID, CORE_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/AddCore',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, REQ_ID, REQ_NAME, DOOR_ID, CORE_ID })    
};

  try {
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function removeCore( ORG_ID, REQ_ID, REQ_NAME, DOOR_ID, CORE_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/RemoveCore',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, REQ_ID, REQ_NAME, DOOR_ID, CORE_ID })    
};

  try {
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function saveCore( ORG_ID, CORE_ID, STATUS, PRODUCT, NOTES ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/SaveCore',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, CORE_ID, STATUS, PRODUCT, NOTES })    
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchDoors( ORG_ID, DOOR_ID, REQ_ID, REQ_NAME, GUID, COREMARKID,  LOCATION_ID, KEYMARK, KEYWAY, SYSTEM ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/FetchDoors',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, DOOR_ID, REQ_ID, REQ_NAME, GUID, COREMARKID,  LOCATION_ID, KEYMARK, KEYWAY, SYSTEM })    
};

  try {
      // console.log(config.data)
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function storeDoor( reQ_ID, reQ_NAME, orG_ID, dooR_ID, dooR_NUMBER, area, description, type, locatioN_ID, locatioN_NAME, notes, deleted, handing, hardware, FUNCTION, closer, 
  thickness, setback, woodmetal, codE_PEG_ID, eleV1_ID, eleV2_ID, frame, doortype, eaC1, eaC2, eaC3, eaC4, eaC5, eaC6, eaC7, eaC8, eaC9, eaC10, closeR_MFG, readeR_IN, readeR_OUT, 
  guid, piC1_DESC, piC2_DESC, piC3_DESC, piC4_DESC, piC5_DESC, piC6_DESC, piC7_DESC, piC8_DESC ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/StoreDoor',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ reQ_ID, reQ_NAME, orG_ID, dooR_ID, dooR_NUMBER, area, description, type, locatioN_ID, locatioN_NAME, notes, deleted, handing, hardware, FUNCTION, closer, 
    thickness, setback, woodmetal, codE_PEG_ID, eleV1_ID, eleV2_ID, frame, doortype, eaC1, eaC2, eaC3, eaC4, eaC5, eaC6, eaC7, eaC8, eaC9, eaC10, closeR_MFG, readeR_IN, readeR_OUT, 
    guid, piC1_DESC, piC2_DESC, piC3_DESC, piC4_DESC, piC5_DESC, piC6_DESC, piC7_DESC, piC8_DESC })    
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function deleteDoor( DOOR_ID, ORG_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/DeleteDoor',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ DOOR_ID, ORG_ID })    
};

  try {
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function fetchDoorHist( ORG_ID, DOOR_ID, REQ_ID, REQ_NAME, GUID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/FetchDoorHist',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, DOOR_ID, REQ_ID, REQ_NAME, GUID })    
};

  try {
      // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function fetchAreas( ORG_ID, REQ_ID, REQ_NAME, LOCATION_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/FetchAreas',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, REQ_ID, REQ_NAME, LOCATION_ID })    
};

  try {
      const response = await axios(config)
      return response.data.arealist
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function fetchGroups( ORG_ID, GROUP_ID, REQ_ID, REQ_NAME, GUID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/FetchGroups',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, GROUP_ID, REQ_ID, REQ_NAME, GUID })    
};

  try {
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function fetchLocations( ORG_ID, REQ_ID, REQ_NAME, GUID, LOCATION_ID, GROUP_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/FetchLocations',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, REQ_ID, REQ_NAME, GUID, LOCATION_ID, GROUP_ID })    
};

  try {
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function storeGroup( reQ_ID, reQ_NAME, orG_ID, grouP_ID, name, desc, notes, guid ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/StoreGroup',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ reQ_ID, reQ_NAME, orG_ID, grouP_ID, name, desc, notes, guid })    
};

  try {
    // console.log(config.data)      
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function deleteGroup( reQ_ID, reQ_NAME, orG_ID, grouP_ID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/DeleteGroup',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ reQ_ID, reQ_NAME, orG_ID, grouP_ID})    
};

  try {
      const response = await axios(config)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function storeLocation( reQ_ID, reQ_NAME, orG_ID, locatioN_ID, loC_NAME, addR1, addR2, addR3, city, state, zip, description, deleted, notes, grouP_ID, 
  loC_NUM, CLASS, domaiN_ID, guid, grouP_NAME, domaiN_NAME ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/StoreLocation',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ reQ_ID, reQ_NAME, orG_ID, locatioN_ID, loC_NAME, addR1, addR2, addR3, city, state, zip, description, deleted, notes, grouP_ID, 
    loC_NUM, CLASS, domaiN_ID, guid, grouP_NAME, domaiN_NAME })    
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function deleteLocation( reQ_ID, reQ_NAME, orG_ID, locatioN_ID ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Doors/DeleteLocation',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ reQ_ID, reQ_NAME, orG_ID, locatioN_ID })    
};

  try {
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchUnissuedKeys(UIY_M1ORGID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;
  // let UIY_M1ORGID = parsedUserData.uiU_M1ORGID;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/users/FetchUnissuedKeys',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UIY_M1ORGID })
};

  try {
      const response = await axios(config) 
      console.log('unissued keys', response)     
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function storeIssuedKey(fileName, selectedKey) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;
  let UIY_M1ORGID = parsedUserData.uiU_M1ORGID;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/users/StoreIssuedKey',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UIY_M1ORGID,  fileName, selectedKey})
};

  try {
      const response = await axios(config)      
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function IssueKey(uiY_M1ORGID, UIY_PERMANENT, UIY_ID, UIY_DUEDATE, UIY_STATUS, UIY_EMPLOYEEID, SIGFILE ) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/IssueKey',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ uiY_M1ORGID, UIY_PERMANENT, UIY_ID, UIY_DUEDATE, UIY_STATUS, UIY_EMPLOYEEID, SIGFILE })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function ReturnKey(uiY_M1ORGID, UIY_ID, RETSIGFILE, UIY_EMPLOYEEID, UIY_LOGGEDINID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/ReturnKey',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ uiY_M1ORGID, RETSIGFILE, UIY_ID, UIY_EMPLOYEEID, UIY_LOGGEDINID })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function FetchKeyEvents(ORG_ID, KEY_ID, USER_ID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Records/FetchKeyEvents',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, KEY_ID, USER_ID })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function FetchDept(UID_M1ORGID, UID_ID, UID_GUID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/users/FetchDept',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UID_M1ORGID, UID_ID, UID_GUID })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function StoreDept(uiD_M1ORGID, uiD_ID, uiD_NAME, uiD_NOTES, uiD_DESC, uiD_DEPTCODE, uiD_GUID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/users/StoreDept',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ uiD_M1ORGID, uiD_ID, uiD_NAME, uiD_NOTES, uiD_DESC, uiD_DEPTCODE, uiD_GUID })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function DeleteDept(UID_M1ORGID, UID_ID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/users/DeleteDept',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ UID_M1ORGID, UID_ID })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
async function getTickets(ORG_ID, GUID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Support/GetTickets',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, GUID })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function getTicketDetails(ORG_ID, TICKET_NO) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Support/GetTicketDetails',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ ORG_ID, TICKET_NO })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function createTicket(orG_ID, tickeT_STATUS, closE_DATE, lasT_UPDATED_BY, tickeT_SUBJECT, tickeT_DESC, priority, contacT_METHOD, tickeT_CONTACT, contacT_EMAIL, contacT_PHONE, category, filE1_PATH, filE2_PATH, filE3_PATH, filE4_PATH, filE5_PATH, EMAIL_TO) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Support/CreateTicket',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ orG_ID, tickeT_STATUS, closE_DATE, lasT_UPDATED_BY, tickeT_SUBJECT, tickeT_DESC, priority, contacT_METHOD, tickeT_CONTACT, contacT_EMAIL, contacT_PHONE, category, filE1_PATH, filE2_PATH, filE3_PATH, filE4_PATH, filE5_PATH, EMAIL_TO })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function createTicketDetail(orG_ID, tickeT_NO, updateD_BY, comment, filE1_PATH, filE2_PATH, filE3_PATH, filE4_PATH, filE5_PATH) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Support/CreateTicketDetail',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ orG_ID, tickeT_NO, updateD_BY, comment, filE1_PATH, filE2_PATH, filE3_PATH, filE4_PATH, filE5_PATH })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}

async function updateTicket(orG_ID, tickeT_NO, tickeT_STATUS, closE_DATE, lasT_UPDATED_BY, tickeT_SUBJECT, tickeT_DESC, priority, contacT_METHOD, tickeT_CONTACT, contacT_EMAIL, contacT_PHONE, category, filE1_PATH, filE2_PATH, filE3_PATH, filE4_PATH, filE5_PATH) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
  method: 'post',
  url: serverAddr.apiUrl + '/Support/UpdateTicket',
  headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
  },
  data : JSON.stringify({ orG_ID, tickeT_NO, tickeT_STATUS, closE_DATE, lasT_UPDATED_BY, tickeT_SUBJECT, tickeT_DESC, priority, contacT_METHOD, tickeT_CONTACT, contacT_EMAIL, contacT_PHONE, category, filE1_PATH, filE2_PATH, filE3_PATH, filE4_PATH, filE5_PATH })
};

  try {
    // console.log(config.data)
      const response = await axios(config)
      // console.log(response.data)
      return response.data
  } catch (error) {
      console.log(error);
      return false;
  }
}